import styled from 'styled-components'

const Button = styled.span`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  text-overflow: ellipsis;
  font-size: 90%;
  overflow: hidden;
  display: block;
  cursor: pointer;
  text-align: center;

  background-color:  ${({ theme, nofill = false }) =>
    (nofill && 'transparent') || theme.primary}
  color:  ${({ theme, nofill = false }) =>
    (nofill && theme.textDefault) || theme.textInverse}


  &:hover {
    background-color: ${({ theme, nofill = false }) =>
      (nofill && '#0001') || theme.primaryLight};
  }

  &:active {
    background-color: ${({ theme, nofill = false }) =>
      (nofill && '#0002') || theme.primaryDark};
  }
`

export default Button
