import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import BannersDashboard from './Dashboard'

const Banners = () => {
  // let { path } = useRouteMatch()
  // const toPath = end => `${path}${end}`

  return (
    <Switch>
      <Route path={'/'}>
        <BannersDashboard />
      </Route>
    </Switch>
  )
}

export default Banners
