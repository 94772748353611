import { isUndefined, omit, omitBy } from 'lodash'
import React, { useRef } from 'react'
import MeditationForm, {
  MeditationFormData,
} from 'src/components/forms/meditation'
import { Meditation, MeditationAdapter } from 'src/entities/Meditation'
import { SimpleThemeColor } from 'src/entities/enums'
import CRUDMaker from 'src/pages/crud-maker'
import { uploadFile } from 'src/util/firestore-util'
import { firestore } from 'firebase'

const MeditationsCRUD = () => {
  const adapter = useRef(new MeditationAdapter()).current
  return (
    <CRUDMaker<MeditationFormData, Meditation>
      entityName='Meditations'
      Form={MeditationForm}
      adapter={adapter}
      onSubmit={async (item, id) => {
        const db = firestore()
        const {
          audio_src_url,
          cover_photo_url,
          author,
          categories,
          tags,
          growth_points,
          mood_categories,
          suit_categories,
          ...data
        } = item
        const dbItem: Omit<Meditation, 'cover_photo_url' | 'audio_src_url'> = {
          author: db.collection('authors').doc(author),
          tags: tags?.map(t => db.collection('meditation_tags').doc(t.key)),
          categories: categories.map(t =>
            db.collection('meditation_categories').doc(t.key)
          ),
          growth_points: growth_points.reduce((acc, cur) => {
            acc[cur.key] = cur.data
            return acc
          }, {} as Record<string, number>),
          mood_categories: mood_categories.map(t =>
            db.collection('mood_categories').doc(t.key)
          ),
          suit_categories: suit_categories.map(t =>
            db.collection('suit_categories').doc(t.key)
          ),
          ...data,
        }

        if (data.created_at && !data._created_at) {
          dbItem._created_at = data.created_at
        }

        if (data._enabled) dbItem.enabled = data._enabled

        if (!id) {
          // @ts-expect-error
          id = await adapter.set(omitBy(dbItem, isUndefined))
        } else {
          await adapter.update(id, omitBy(dbItem, isUndefined))
        }
        if (cover_photo_url instanceof File) {
          const url = await uploadFile(
            adapter.collectionName + '/meditation_audio',
            'png',
            id,
            cover_photo_url
          )
          adapter.update(id, {
            cover_photo_url: url,
          })
        }
        if (audio_src_url instanceof File) {
          const url = await uploadFile(
            adapter.collectionName + '/meditation_cover',
            'mp3',
            id,
            audio_src_url
          )
          adapter.update(id, {
            audio_src_url: url,
          })
        }
      }}
      dashboardColumns={[
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
      ]}
    />
  )
}

export default MeditationsCRUD
