import React from 'react'
import { withTheme } from 'styled-components'
import Spinners from 'react-activity'
import 'react-activity/dist/react-activity.css'

const ThemedSpinners = {}

const resolveColorVar = variant => {
  switch (variant) {
    case 'primary':
    case 'secondary':
      return variant
    case 'inverse':
      return 'textInverse'
    default:
      return 'textDefault'
  }
}

for (const spinner in Spinners) {
  ThemedSpinners[spinner] = withTheme(
    ({ theme, variant = 'primary', ...props }) => {
      return React.createElement(Spinners[spinner], {
        color: theme[resolveColorVar(variant)],
        ...props,
      })
    }
  )
}

export default ThemedSpinners

export const {
  Dots,
  Levels,
  Sentry,
  Spinner,
  Squares,
  Digital,
  Bounce,
  Windmill,
} = ThemedSpinners
