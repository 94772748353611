import { FormHelperText } from '@material-ui/core'
import { FormikContext, FormikContextType } from 'formik'
import React, { useContext } from 'react'
import { StyledInputLabel } from './styled-basics'

export interface CustomFormikInputProps {
  name: string
  label?: string
  helperText?: string
  children: (
    props: { error: boolean; value: any; name: string },
    formik: FormikContextType<any>
  ) => JSX.Element
}

const CustomFormikInput: React.FC<CustomFormikInputProps> = ({
  name,
  label = '',
  helperText,
  children,
}) => {
  const formik = useContext(FormikContext)
  const { values, errors } = formik

  return (
    <>
      <StyledInputLabel>{label}</StyledInputLabel>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {children(
        {
          error: !!errors[name],
          value: values[name],
          name,
        },
        formik
      )}
      {!!errors[name] && (
        <FormHelperText error={true}>{errors[name]}</FormHelperText>
      )}
    </>
  )
}

export default CustomFormikInput
