/*
  Hook recipe from: https://usehooks.com/useRequireAuth/
 */

import { useEffect } from 'react'
import useAuth from './useAuth'
import useRouter from './useRouter'

//FIXME find out how to make redirectURL relative to basename
function useRequireAuth(redirectUrl = '/login') {
  const auth = useAuth()
  const router = useRouter()

  // If auth.user is false that means we're not
  // logged in and should redirect.
  useEffect(() => {
    if (auth.user === false) {
      router.push(redirectUrl)
    }
  }, [auth, router])

  return auth
}

export default useRequireAuth
