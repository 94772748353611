import React, { useState } from 'react'
import Chip from '@material-ui/core/Chip'
import SearchResultComponent from './SearchResultComponent'

function ChipList({ list = [], handleDelete, ...props }) {
  return (
    <div {...props}>
      {list.map(data => {
        return (
          <Chip
            key={data.key}
            label={data.label}
            onDelete={() => handleDelete(data)}
          />
        )
      })}
    </div>
  )
}

function ChipMultiSelect({
  list: chipList,
  onListChange,
  onSearch,
  searchProps = {},
  ...props
}) {
  const [query, setQuery] = useState()

  const handleDelete = chipToDelete => {
    setQuery(undefined)
    onListChange(chipList.filter(chip => chip.key !== chipToDelete.key))
  }

  const handleSearch = async query => {
    const res = await onSearch(query)
    return res.filter(r => chipList.findIndex(c => c.key === r.key) < 0)
  }

  const handleSelect = selected => {
    setQuery(undefined)
    onListChange([...chipList, selected])
  }

  return (
    <div>
      <SearchResultComponent
        query={query}
        onQueryChange={({ target }) => setQuery(target.value)}
        renderResult={el => <span>{el.label}</span>}
        onSearch={handleSearch}
        onResultSelect={handleSelect}
        {...searchProps}
      />
      <ChipList
        style={{ marginTop: 4 }}
        list={chipList}
        handleDelete={handleDelete}
      />
    </div>
  )
}

export default ChipMultiSelect
