import { useEffect, useRef } from 'react'

/*
  Small wrapper to 'useEffect'
  Does not trigger on the initial render of the component
 */
export default function (fn, deps) {
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    return fn()
    // eslint-disable-next-line
  }, deps)
}
