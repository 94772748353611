import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core'
import { useField } from 'formik'
import * as React from 'react'
import { useFieldExtensions } from './util'
export interface SelectFieldProps<T = any> extends SelectProps {
  name: string
  label: string
  options: T[]
  itemSelector?: (
    item: T,
    index: number
  ) => { item: string | JSX.Element; value: any }
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  options,
  itemSelector = item => ({
    item: item?.toString(),
    value: item,
  }),
  ...props
}) => {
  const [field, meta] = useField(name)
  const ext = useFieldExtensions(meta)

  return (
    <FormControl>
      <InputLabel children={label} error={ext.error} />
      <Select {...field} {...ext} {...props} value={field.value ?? ''}>
        {options.map((option, i) => {
          const { item, value } = itemSelector(option, i)
          return (
            <MenuItem key={item} value={value}>
              {item}
            </MenuItem>
          )
        })}
      </Select>
      {ext.error && <FormHelperText error={ext.error} children={meta.error} />}
    </FormControl>
  )
}

export default SelectField
