/*
  Hook recipe adapted from: https://usehooks.com/useAuth/
 */

import React, { useState, useEffect, useContext, createContext } from 'react'
import firebase from 'firebase/app'

const authContext = createContext()

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [role, setRole] = useState(null)

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = async (email, password) => {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
    const { claims = {} } = await response.user.getIdTokenResult()
    if (
      typeof claims.admin_role !== 'string' ||
      !claims.admin_role.match(/^(admin|owner)$/)
    ) {
      throw Error('User has insufficient permissions')
    }
    return response.user
  }

  const signOut = async () => {
    await firebase.auth().signOut()
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const { claims = {} } = await user.getIdTokenResult()
        if (
          typeof claims.admin_role === 'string' &&
          claims.admin_role.match(/^(admin|owner)$/)
        ) {
          setUser(user)
          setRole(claims.admin_role)
        } else {
          setRole(false)
          signOut()
        }
      } else {
        setUser(false)
      }
    })
  }, [])

  // Return the user object and auth methods
  return {
    user,
    role,
    signIn,
    signOut,
  }
}

export default useAuth
