import { FirestoreEntity, FirestoreCollectionAdapter } from './base'

export interface MeditationCategory extends FirestoreEntity {
  name: string
  description?: string
  background_color: string
  image_src?: string
}

export class MeditationCategoryAdapter extends FirestoreCollectionAdapter<MeditationCategory> {
  constructor() {
    super('meditation_categories')
  }
}
