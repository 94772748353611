import React, { useContext } from 'react'
import styled from 'styled-components'

export const formContext = React.createContext()

/**
 * Content-wrapped 'form' tag component which provides formState
 * @param       {Object} state The form's state used for context
 * This should be the entirety of the state generated from useForm
 * @param       {Object} props any other props, goes to the 'form' tag
 * @constructor
 */
export function Form({ state, ...props }) {
  return (
    <formContext.Provider value={state}>
      <form {...props} />
    </formContext.Provider>
  )
}

// Standard error text style
export const Error = styled.div`
  color: red;
  fontsize: 12pt;
`

/**
 * Bundles input with an error message and event listeners based on form context
 * @param       {[type]} name                Name used for form
 * @param       {String} [Component="input"] The component type to pass values into, defaults to 'form'
 * @param       {[type]} props               Any other props for  Component
 * @constructor
 */
export function Input({ name, Component = 'input', ...props }) {
  const {
    values,
    errors,
    visited,
    handleChange,
    handleFileChange,
    handleBlur,
  } = useContext(formContext)
  return (
    <>
      <Component
        name={name}
        form='useForm'
        value={props.type === 'file' ? undefined : values[name]}
        checked={props.type === 'checkbox' ? values[name] : undefined}
        onChange={props.type === 'file' ? handleFileChange : handleChange}
        onBlur={props.type === 'file' ? undefined : handleBlur}
        {...props}
      />
      {visited[name] && errors[name] && <Error>{errors[name]}</Error>}
    </>
  )
}
