import React, { useState, useEffect, useCallback } from 'react'
import MeditationForm from 'src/components/forms/meditation'
import MeditationQuery from 'src/services/FirestoreQueryService/MeditationQuery'
import ProgressDialog from 'src/components/ProgressDialog'

import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { simpleObjectReducer } from 'src/util/reducers'
import { aaxios } from 'src/util/app-axios'

export default function () {
  const [meditation, setMeditation] = useState()

  const { id: meditationId } = useParams()

  useEffect(() => {
    MeditationQuery.getById(meditationId).then(med => {
      console.log(med)
      setMeditation(med)
    })
  }, [meditationId])

  const handleSubmit = useCallback(
    async v => {
      setDialogState({ open: true })

      try {
        // Updating cover photo if defined
        if (v.cover !== undefined) {
          setDialogState({
            text: 'Updating cover photo',
          })
          await MeditationQuery.uploadCover(meditation.id, v.cover)
        }
        // Updating audio file if defined
        if (v.audio !== undefined) {
          setDialogState({
            text: 'Updating meditation audio',
          })
          await MeditationQuery.uploadAudio(meditation.id, v.audio)
        }
        // Filter out undefined values (because that means they have not been modified)
        const newFieldValues = _.pickBy(
          {
            name: v.title,
            description: v.description,
            is_premium: v.premium,
            author_id: v.authorId,
            categories: v.categories.map(c => c.key),
            growth_points: _.chain(v.growthPoints)
              .keyBy('key')
              .mapValues('data')
              .value(),
            enabled: v.enabled,
            display_time: v.displayTime,
            tags: v.tags?.map(t => t.key),
          },
          val => val !== undefined
        )

        const axios = await aaxios()
        await axios.patch(
          `/content/meditations/${meditationId}`,
          newFieldValues
        )

        setDialogState({
          text: 'Update complete!',
          completed: true,
        })
      } catch (e) {
        setDialogState({
          error: true,
          text: `There was an error uploading:\n${
            e.response?.data?.error?.message ?? e.message
          }`,
        })
        console.error(e.response?.data ?? e)
      }
    },
    [meditation]
  )

  const [dialogState, setDialogState] = React.useReducer(simpleObjectReducer, {
    text: 'Uploading...',
    open: false,
    completed: false,
    error: false,
  })

  const handleClose = () => {
    setDialogState({ open: false })
  }

  if (meditation === undefined) return <div>Loading</div>

  if (meditation === null)
    return <div>It looks like this meditation does not exist.</div>

  return (
    <>
      <MeditationForm
        initialMeditation={meditation.data}
        isEdit={true}
        onSubmit={handleSubmit}
      />
      <ProgressDialog
        progressText={dialogState.text}
        open={dialogState.open}
        completed={dialogState.completed}
        error={dialogState.error}
        onClose={handleClose}
      />
    </>
  )
}
