import { firestore, auth } from 'firebase'
import { cloneDeep } from 'lodash'
import { BannerConfigurationButtonActionInput } from 'src/components/forms/BannerConfig/ButtonAction/schema'
import { BannerConfigurationInput } from 'src/components/forms/BannerConfig/schema'
import { DataPair } from 'src/components/material-x/PropertyList'
import {
  AnyButtonAction,
  BannerConfiguration,
  BannerConfigurationDocument,
  ScreenNavigationButtonAction,
} from 'src/types'

const configColl = () => firestore().collection('banner_configurations')

export const getAllBannerConfigurations = () =>
  configColl()
    .get()
    .then(({ docs }) =>
      docs
        .filter(doc => doc.exists)
        .map(
          doc =>
            ({ ...doc.data()!, uid: doc.id } as BannerConfigurationDocument)
        )
    )

const prepareButtonActionForFirestore: (
  action: BannerConfigurationButtonActionInput
) => AnyButtonAction = action => {
  // HACK: Not sure if this is the best way to do this
  const newAction = cloneDeep(action) as any
  if (Array.isArray(action.screenParameters)) {
    newAction.screenParameters = (action.screenParameters as DataPair[]).reduce(
      (acc, { key, value }) => {
        acc[key] = value
        return acc
      },
      {} as Record<string, any>
    )
  } else if (action.screenParameters) {
    delete newAction.screenParameters
  }
  return newAction
}

export const prepareBannerDataForFirestore: (
  values: BannerConfigurationInput
) => BannerConfiguration = values => ({
  size: values.size,
  content: {
    title: values.title ?? null,
    text: values.text ?? null,
  },
  image:
    values.imageSrc && values.options.imagePosition
      ? {
          imageSource: values.imageSrc,
          position: values.options.imagePosition,
        }
      : null,
  background:
    values.backgroundColor || values.backgroundSrc
      ? {
          backgroundColor: values.backgroundColor ?? null,
          imageSource: values.backgroundSrc ?? null,
        }
      : null,
  button:
    values.buttonText && values.buttonAction
      ? {
          text: values.buttonText,
          icon: values.buttonIcon ?? null,
          color: values.buttonColor ?? null,
          backgroundColor: values.buttonBackgroundColor ?? null,
          action: prepareButtonActionForFirestore(values.buttonAction),
        }
      : null,
  options: {
    orderType: values.options.orderType!,
    afterRow: !!values.options.afterRow
      ? parseInt(values.options.afterRow.toString())
      : 1,
    minVersion: values.options.minVersion ?? null,
  },
})

const undef = <T = any>(item?: T) => item ?? undefined

export const loadBannerDataIntoForm = (
  data: BannerConfiguration
): Partial<BannerConfigurationInput> => {
  const { background, button, content, image, options, size } = cloneDeep(data)

  // HACK: Required to treat as any, incase there is historical data saved of a different action type
  let buttonAction = undef(button?.action) as any
  if (buttonAction && !!buttonAction.screenParameters) {
    if (typeof buttonAction.screenParameters !== 'object') {
      delete buttonAction['screenParameters']
    } else {
      buttonAction.screenParameters = Object.entries(
        (buttonAction.screenParameters as ScreenNavigationButtonAction['screenParameters']) ??
          {}
      ).map(([key, value]) => ({
        key,
        value,
      }))
    }
  }

  return {
    backgroundColor: undef(background?.backgroundColor),
    backgroundSrc: undef(background?.imageSource),
    // @ts-expect-error THIS IS JUST INITIAL i dont understand
    buttonAction: undef(button?.action),
    buttonBackgroundColor: undef(button?.backgroundColor),
    buttonColor: undef(button?.color),
    buttonIcon: undef(button?.icon),
    buttonText: data.button?.text,
    imageSrc: image?.imageSource,
    options: {
      afterRow: undef(options.afterRow),
      imagePosition: image?.position,
      minVersion: undef(options.minVersion),
      orderType: options.orderType,
    },
    size,
    text: undef(content.text),
    title: undef(content.title),
  }
}

export function saveBannerConfiguration(
  data: BannerConfigurationDocument
): Promise<any> {
  if (!data.uid || data.uid.length === 0) return configColl().doc().set(data)
  else return configColl().doc(data.uid).set(data)
}

export function setBannerVisiblity(id: string, enabled: boolean) {
  return configColl()
    .doc(id)
    .update({
      enabled,
      last_updated_by: auth().currentUser?.email ?? 'Unknown',
      last_updated_at: firestore.Timestamp.now(),
    })
}

export function deleteBanner(id: string) {
  return configColl().doc(id).delete()
}
