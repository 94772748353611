import React from 'react'
import styled from 'styled-components'

export const Column = styled.div``

export const Row = styled.div.attrs(props => ({
  spacing: props.spacing || '1rem',
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ nowrap }) => (nowrap ? 'nowrap' : 'wrap')};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'}

  margin-right: -${({ spacing }) => spacing};
  margin-bottom: -${({ spacing }) => spacing};

  & > ${Column} {
    padding-right: ${({ spacing }) => spacing};
    padding-bottom: ${({ spacing }) => spacing};
  }
`
