import React from 'react'

import { Route, useRouteMatch, Switch } from 'react-router-dom'

import New from './New'
import SetDailyMeditation from './SetDailyMeditation'
import Edit from './edit'
import Search from './Search'
import MeditationsCRUD from '../crud/Meditation'

export default function () {
  let { path } = useRouteMatch()
  const toPath = end => `${path}${end}`

  return (
    <Switch>
      <Route path={toPath('/daily-meditation')}>
        <SetDailyMeditation />
      </Route>
      <Route path={toPath('/search')}>
        <Search />
      </Route>
      <Route path={toPath('')}>
        <MeditationsCRUD />
      </Route>
    </Switch>
  )
}
