import { FirestoreEntity, FirestoreCollectionAdapter } from './base'

export interface SuitCategory extends FirestoreEntity {
  name: string
  description?: string
  image_src?: string
}

export class SuitCategoryAdapter extends FirestoreCollectionAdapter<SuitCategory> {
  constructor() {
    super('suit_categories')
  }
}
