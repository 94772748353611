import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'
import CustomFormikInput, { CustomFormikInputProps } from './CustomFormikInput'

type FormikCheckboxProps = Omit<CustomFormikInputProps, 'children'>

const FormikCheckbox = (props: FormikCheckboxProps) => (
  <CustomFormikInput {...props}>
    {({ value, name }, { setFieldValue }) => (
      <Checkbox
        checked={value}
        name={name}
        onChange={({ currentTarget }) =>
          setFieldValue(name, currentTarget.checked)
        }
      />
    )}
  </CustomFormikInput>
)

export default FormikCheckbox
