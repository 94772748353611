import React from 'react'

import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import IDialogPropTypes from 'rc-dialog/lib/IDialogPropTypes'

type OnCloseEvent = Parameters<NonNullable<IDialogPropTypes['onClose']>>[0]

export default class SimpleDialog extends React.Component {
  state = {
    visible: false,
  }

  openDialog = () => {
    this.setState({ visible: true })
  }

  handleClose = async (e: OnCloseEvent) => {
    const { onClose } = this.props as { onClose: IDialogPropTypes['onClose'] }
    if (onClose) await onClose(e)
    this.setState({ visible: false })
  }

  render() {
    const { visible } = this.state
    return (
      <Dialog visible={visible} {...this.props} onClose={this.handleClose} />
    )
  }
}
