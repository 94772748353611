import * as yup from 'yup'
import buttonSchema from './ButtonAction/schema'
import iconNames from './icon-names.json'

const yupColorValArgs: [RegExp, string] = [
  /^#[a-fA-F0-9]{3,6}$/i,
  'Must be a valid hex color code',
]

const schema = yup.object({
  size: yup.mixed().required(),
  title: yup.string(),
  text: yup.string(),
  buttonText: yup.string().max(100),
  buttonIcon: yup.string().optional().oneOf(iconNames),
  buttonBackgroundColor: yup.string().matches(...yupColorValArgs),
  buttonColor: yup.string().matches(...yupColorValArgs),
  buttonAction: buttonSchema,
  backgroundColor: yup.string().matches(...yupColorValArgs),
  backgroundSrc: yup.string().url(),
  imageSrc: yup.string().url(),
  options: yup.object({
    imagePosition: yup.mixed<'left' | 'right'>().oneOf(['left', 'right']),
    orderType: yup
      .mixed<'above-highlighted' | 'below-highlighted' | 'after-row'>()
      .oneOf(['above-highlighted', 'below-highlighted', 'after-row'])
      .required(),
    afterRow: yup.number().integer().positive(),
    minVersion: yup
      .string()
      .optional()
      .matches(/^\d\.\d\.\d$/, 'Must be a valid version (x.y.z)'),
  }),
})

export type BannerConfigurationInput = yup.TypeOf<typeof schema>

export default schema
