import { FirestoreEntity, FirestoreCollectionAdapter } from './base'

export interface MoodCategory extends FirestoreEntity {
  name: string
  description?: string
  background_color: string
  image_src?: string
}

export class MoodCategoryAdapter extends FirestoreCollectionAdapter<MoodCategory> {
  constructor() {
    super('mood_categories')
  }
}
