import { Button } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import CustomFormikInput from 'src/components/form-components/CustomFormikInput'
import FormikCheckbox from 'src/components/form-components/FormikCheckbox'
import FormikTextField from 'src/components/form-components/FormikTextField'
import FileInput from 'src/components/form-components/FileInput'
import { StyledForm } from 'src/components/form-components/styled-basics'
import { MeditationCategory } from 'src/entities/MeditationCategory'
import SimpleSelect from '../../form-components/SimpleSelect'
import { CRUDFormProps } from '../types'
import validationSchema from './validation'

const CategoryColor = {
  DeepRose: 'deep_rose',
  DeepRoseLight: 'deep_rose_light',
  Bittersweet: 'bittersweet',
  BittersweetLight: 'bittersweet_light',
  Orchid: 'orchid',
  OrchidLight: 'orchid_light',
  YellowOrange: 'yellow_orange',
  YellowOrangeLight: 'yellow_orange_light',
}

const colorsAsDataList = Object.entries(CategoryColor).map(([label, key]) => ({
  label,
  key,
}))

export interface MeditationCategoryFormData {
  name: string
  description: string
  background_color: string
  image_url: File | string
  _enabled: boolean
}

const MeditationCategoryForm = ({
  item,
  onSubmit,
}: CRUDFormProps<MeditationCategory, MeditationCategoryFormData>) => {
  const formik = useFormik({
    initialValues: {
      name: item?.name ?? '',
      description: item?.description ?? '',
      background_color: item?.background_color ?? '',
      image_url: item?.image_src ?? '',
      _enabled: item?._enabled ?? true,
    },
    validationSchema,
    onSubmit,
  })
  const { handleSubmit, values, setFieldValue, errors } = formik

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <FormikCheckbox name='_enabled' label='Enabled' />

        <FormikTextField name='name' label='Name' />

        <FormikTextField
          style={{ width: 300 }}
          name='description'
          label='Description'
        />

        <CustomFormikInput
          name='background_color'
          label='Background Color'
          helperText='Background color determines the color of components which display the category image. Consult the designs for clarification.'
        >
          {inputProps => (
            <SimpleSelect
              style={{ minWidth: 200 }}
              data={colorsAsDataList}
              onChange={({ target }) => {
                setFieldValue(inputProps.name, target.value)
              }}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <CustomFormikInput name='image_url' label='Image'>
          {({ value, ...inputProps }) => (
            <FileInput
              type='file'
              onChange={({ currentTarget }) => {
                // @ts-expect-error
                setFieldValue(inputProps.name, currentTarget.files[0])
              }}
              // @ts-expect-error
              file={values.image_url}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <Button
          variant='contained'
          color='primary'
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </StyledForm>
    </FormikProvider>
  )
}

export default MeditationCategoryForm
