import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Button from 'src/components/Button'
import useAuth from 'src/hooks/useAuth'

const Container = styled.div`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.textInverse};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`

const Title = styled.h2`
  cursor: pointer;
`

export default function Header({ selectedIndex }) {
  const history = useHistory()
  const { signOut } = useAuth()

  const handleTitleClick = () => {
    //FIXME find out how to make relative to basename
    history.push('/')
  }

  const handleLogout = () => {
    signOut()
  }

  return (
    <Container>
      <Title onClick={handleTitleClick}>Prana Heart Admin Dashboard</Title>
      <Button nofill onClick={handleLogout}>
        Log out
      </Button>
    </Container>
  )
}
