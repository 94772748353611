import { MenuItem, Select, SelectProps } from '@material-ui/core'
import React from 'react'

interface SimpleSelectProps extends SelectProps {
  data: { label: string; key: string }[]
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({ data = [], ...props }) => {
  return (
    <Select {...props}>
      {data.map((d, i) => (
        <MenuItem key={d.key} value={d.key}>
          {d.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SimpleSelect
