import React, { useEffect, useMemo, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { IconButton, Paper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import styled from 'styled-components'

/*
{
  selected:[{
    key,
    data: {}
  }], 
  options:[{
    label,
    key,
    hidden,
  }]
}
*/

const Container = styled.div`
  & .select-dropdown-container {
    min-width: 120px;
  }

  & .selected-list-container {
    margin-top: 0.5rem;
  }

  & .selected-list-container {
    min-width: 120px;
  }
`

const defaultRenderItem = handlers => item => {
  return (
    <SelectedItem
      className='selected-item'
      key={item.key}
      label={item.label}
      onDeleteClick={() => handlers.handleSelectedDelete(item)}
    />
  )
}

function MultiSelectList({
  className,
  options = [],
  selected = [],
  renderItem = defaultRenderItem,
  onSelectionChange,
  selectProps,
  labelProps,
}) {
  useEffect(() => {
    onSelectionChange(selected)
    // eslint-disable-next-line
  }, [selected.length])

  // const [selectedKeys, setSelectedKeys] = useState(selected.map(s => s.key))

  const availableOptions = useMemo(() => {
    return options.filter(o => selected.every(s => s.key !== o.key))
  }, [selected, options])

  function handleChange({ target }) {
    // setSelectedKeys([...selectedKeys, target.key])
    onSelectionChange([...selected, options.find(x => x.key === target.value)])
  }

  function handleSelectedDelete({ key }) {
    // setSelectedKeys(selectedKeys.filter(s => s === key))
    onSelectionChange(selected.filter(s => s.key !== key))
  }

  // useEffect(() => {
  //   console.log('keys', selectedKeys)
  //   if (!options) return
  //   onSelectionChange(selectedKeys.map(k => options.find(x => x.key === k)))
  //   // onSelectionChange(selectedKeys)
  // }, [options])

  return (
    <Container className={`multi-select ${className}`}>
      <FormControl className='select-dropdown-container'>
        {labelProps?.children && (
          <InputLabel
            className='select-dropdown-label'
            id='select-dropdown-label'
            {...labelProps}
          />
        )}
        <Select
          className='select-dropdown'
          labelId='select-dropdown-label'
          id='select-dropdown'
          value=''
          onChange={handleChange}
          {...selectProps}
        >
          {availableOptions.map((o, i) =>
            !o.hidden ? (
              <MenuItem
                className='select-dropdown-option'
                key={i}
                value={o.key}
              >
                {o.label}
              </MenuItem>
            ) : undefined
          )}
        </Select>
      </FormControl>
      {(selected.length ?? 0) !== 0 && (
        <Paper className='selected-list-container'>
          <List className='selected-list'>
            {selected.map(renderItem({ handleSelectedDelete }))}
          </List>
        </Paper>
      )}
    </Container>
  )
}

export function SelectedItem({ label, onDeleteClick, children, ...props }) {
  return (
    <ListItem {...props}>
      {children ?? <ListItemText className='list-item-text' primary={label} />}
      <ListItemSecondaryAction className='list-item-action'>
        <IconButton
          className='list-item-action-button'
          edge='end'
          onClick={onDeleteClick}
        >
          <CloseIcon className='list-item-action-button-icon' />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default MultiSelectList
