import * as React from 'react'
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete'
import { useField } from 'formik'
import { useFieldExtensions } from './util'
import { MaterialXFormikInputBase } from './types'
import { TextField } from '@material-ui/core'

export type AutoCompleteFieldProps<T = any> = Omit<
  AutocompleteProps<T, any, any, any>,
  'renderInput'
> &
  MaterialXFormikInputBase

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  name,
  ...props
}) => {
  const [{ onChange, ...field }, meta, { setValue }] = useField(name)
  const ext = useFieldExtensions(meta)

  return (
    <Autocomplete
      {...ext}
      {...props}
      {...field}
      onChange={(e, value) => setValue(value)}
      renderInput={params => (
        <TextField {...params} label={props.label} margin='normal' />
      )}
    />
  )
}

export default AutoCompleteField
