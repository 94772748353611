import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const ResultItemContainer = styled.div`
  padding: 0.5rem;
  text-overflow: ellipsis;
  ${({ noclick }) => (noclick ? null : 'cursor: pointer;')}

  &:hover {
    ${({ noclick }) => (noclick ? null : 'background-color: #0001')}
  }
`

const ResultContainer = styled.div`
  position: absolute;
  background-color: white;
  top: 0.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.375rem darkgrey;
  min-width: 100%;
  max-height: 50vh;
  z-index: 3;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-flow: column nowrap;
`

function SearchResultComponent({
  query,
  onQueryChange,
  deferDuration = 1000,
  renderResult,
  resultItemContainerStyle = {},
  onSearch,
  onResultSelect,
  inputProps = {},
  ...props
}) {
  const [timeoutId, setTimeoutId] = useState()
  const [results, setResults] = useState()

  // Listen for changes in query's value,
  //   when it changes, attempt to search
  useEffect(() => {
    clearTimeout(timeoutId)
    if (!!query) {
      var id = setTimeout(async () => {
        try {
          setResults(await onSearch(query))
        } catch (e) {
          setResults(false)
        }
      }, deferDuration)
      setTimeoutId(id)
    } else {
      setResults(undefined)
    }
  }, [query])

  // To make element specific selection
  const buildHandleResultsSelect = args => () => {
    onResultSelect(...args)
  }

  // Wraps result component in selectable wrapper
  const _renderResult = (...args) => (
    <ResultItemContainer
      key={`result_${args[1]}`}
      style={resultItemContainerStyle}
      onClick={buildHandleResultsSelect(args)}
    >
      {renderResult(...args)}
    </ResultItemContainer>
  )

  return (
    <div {...props}>
      <input
        type='search'
        value={query}
        onChange={onQueryChange}
        autoComplete='off'
        {...inputProps}
      />
      <div style={{ position: 'relative' }}>
        {results === undefined ? null : (
          <ResultContainer>
            {
              // decide what to display based on 'results' object
              (() => {
                if (results === false || !Array.isArray(results))
                  return (
                    <ResultItemContainer noclick>
                      There was an error fetching your results
                    </ResultItemContainer>
                  )
                if (results.length === 0)
                  return (
                    <ResultItemContainer noclick>
                      No results match your query
                    </ResultItemContainer>
                  )
                return results.map(_renderResult)
              })()
            }
          </ResultContainer>
        )}
      </div>
    </div>
  )
}

export default SearchResultComponent
