import React from 'react'
import styled from 'styled-components'

import Button from 'src/components/Button'
import SimpleDialog from 'src/components/SimpleDialog'
import Spinners from 'src/components/spinners'

import useForm, { Form, Input, useValidation } from 'src/hooks/useForm'
import FileUploadService from 'src/services/FileUploadService'
import { aaxios } from 'src/util/app-axios'

const StyledForm = styled(Form)`
  display: flex
  flex-direction: column
  align-items: flex-start
`

const FormGroup = styled.div`
  margin-bottom: .75rem
  display: flex
  flex-direction: column
  align-items: flex-start
`

export default function () {
  const [dialogState, setDialogState] = React.useState({
    text: 'Uploading...',
  })
  const dialogRef = React.createRef()

  const submit = async ({ name, bio, avatar }) => {
    dialogRef.current.openDialog()
    const setErr = text => {
      setDialogState({
        text,
        isError: true,
      })
    }

    setDialogState({
      text: 'Creating firestore document',
    })
    var res
    try {
      res = await (await aaxios()).post('/create/author', {
        name,
        bio,
      })
    } catch (e) {
      setErr(e.response.data.error.message)
      return
    }

    try {
      const { id } = res.data.data
      setDialogState({
        text: 'Uploading avatar image',
      })
      await FileUploadService.uploadFile('author_avatar', id, avatar)
      setDialogState({
        text: `Complete! In firestore at ${res.data.data.fullpath}`,
      })
    } catch (e) {
      setErr(e)
      console.log(dialogState)
    }
  }

  const validate = useValidation({
    name: ['required'],
    bio: ['required'],
    avatar: ['image_file'],
  })

  const formState = useForm(
    {
      name: '',
      bio: '',
      avatar: '',
    },
    submit,
    validate
  )

  return (
    <>
      <StyledForm state={formState}>
        <FormGroup>
          Name:
          <Input type='text' name='name' />
        </FormGroup>

        <FormGroup>
          Bio:
          <Input
            Component='textarea'
            type='text'
            name='bio'
            rows='5'
            cols='22'
          />
        </FormGroup>

        <FormGroup>
          Avatar:
          <Input type='file' name='avatar' />
        </FormGroup>

        <Button onClick={formState.handleSubmit}>Submit</Button>
      </StyledForm>
      <SimpleDialog ref={dialogRef} maskClosable={false}>
        <Spinners.Dots />
        <p style={(dialogState.isError && { color: 'red' }) || []}>
          {dialogState.text}
        </p>
      </SimpleDialog>
    </>
  )
}
