import React from 'react'
import { Route } from 'react-router-dom'

import useRequireAuth from 'src/hooks/useRequireAuth'
import { Digital } from 'src/components/spinners'

export default function RequireAuthRoute(props) {
  const { user } = useRequireAuth()

  if (!user) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: '6rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Digital />
        <span style={{ marginLeft: '.25rem' }}>Loading</span>
      </div>
    )
  }

  return <Route {...props} />
}
