import React, { useCallback } from 'react'
import { aaxios } from 'src/util/app-axios'

import MeditationForm from 'src/components/forms/meditation'
import MeditationQuery from 'src/services/FirestoreQueryService/MeditationQuery'
import ProgressDialog from 'src/components/ProgressDialog'
import { firestore } from 'firebase'
import { simpleObjectReducer } from 'src/util/reducers'
import _ from 'lodash'

export default function () {
  const handleSubmit = useCallback(async values => {
    // TODO: load in existing categories on load
    setDialogState({ open: true })

    setDialogState({
      open: true,
      text: 'Creating firestore document',
    })
    var res
    try {
      const meditation = {
        name: values.title,
        description: values.description,
        is_premium: values.premium,
        author_id: values.authorId,
        categories: values.categories.map(c => c.key),
        growth_points: _.chain(values.growthPoints)
          .keyBy('key')
          .mapValues('data')
          .value(),
        enabled: values.enabled,
        display_time: values.displayTime,
        tags: values.tags?.map(t => t.key),
      }
      res = await (await aaxios()).post('/content/meditations', meditation)
    } catch (e) {
      setDialogState({
        error: true,
        text: `There was an error uploading:\n${
          e.response?.data?.error?.message ?? e.message
        }`,
      })
      return
    }

    try {
      const { id } = res.data.data

      // Adding cover
      setDialogState({
        text: 'Uploading meditation cover',
      })
      await MeditationQuery.uploadCover(id, values.cover)

      // Adding audio
      setDialogState({
        text: 'Uploading meditation audio',
      })
      await MeditationQuery.uploadAudio(id, values.audio)
      setDialogState({
        text: `Complete! In firestore at ${res.data.data.fullpath}`,
        completed: true,
      })
    } catch (e) {
      setDialogState({
        error: true,
        text: `There was an error uploading:\n${e}`,
      })
    }
  }, [])

  const [dialogState, setDialogState] = React.useReducer(simpleObjectReducer, {
    text: 'Uploading...',
    open: false,
    completed: false,
    error: false,
  })

  const handleClose = () => {
    setDialogState({ open: false })
  }

  return (
    <>
      <MeditationForm isEdit={false} onSubmit={handleSubmit} />
      <ProgressDialog
        progressText={dialogState.text}
        open={dialogState.open}
        completed={dialogState.completed}
        error={dialogState.error}
        onClose={handleClose}
      />
    </>
  )
}
