import { cyup, fileTypeSets } from 'src/util/validation'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup.string().required().min(1),
  description: yup.string(),
  author: yup.string().required(),
  audio_src_url: cyup.ensureFile({
    validFileTypes: ['audio/mp3', 'audio/mpeg'],
    required: true,
  }),
  cover_photo_url: cyup.ensureFile({
    validFileTypes: fileTypeSets.firebaseImage,
    required: false,
  }),
})

export default validationSchema
