import axios from 'axios'
import firebase from 'firebase/app'

var token

async function getToken() {
  if (!token) {
    var resolve
    const waitForUser = new Promise(res => {
      resolve = res
    })
    firebase.auth().onIdTokenChanged(async user => {
      if (user) {
        token = await user.getIdToken()
        resolve()
      }
    })
    await waitForUser
  }
  return token
}

export const aaxios = async () =>
  axios.create({
    baseURL: 'https://us-central1-prana-heart.cloudfunctions.net/admin',
    headers: {
      authorization: 'Bearer ' + (await getToken()),
    },
  })
