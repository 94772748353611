import { omit } from 'lodash'
import React, { useRef } from 'react'
import MeditationCategoryForm, {
  MeditationCategoryFormData,
} from 'src/components/forms/categories'
import {
  MeditationCategory,
  MeditationCategoryAdapter,
} from 'src/entities/MeditationCategory'
import { SimpleThemeColor } from 'src/entities/enums'
import CRUDMaker from 'src/pages/crud-maker'
import { uploadFile } from 'src/util/firestore-util'

const MeditationCategoriesCRUD = () => {
  const adapter = useRef(new MeditationCategoryAdapter()).current
  return (
    <CRUDMaker<MeditationCategoryFormData, MeditationCategory>
      entityName='Meditation Categories'
      Form={MeditationCategoryForm}
      adapter={adapter}
      onSubmit={async (item, id) => {
        const data = omit(item, 'image_url')
        if (!id) {
          id = await adapter.set(data)
        } else {
          await adapter.update(id, {
            background_color: item.background_color as SimpleThemeColor,
            name: item.name,
            description: item.description,
            _enabled: item._enabled,
          })
        }
        if (item.image_url instanceof File) {
          const url = await uploadFile(
            adapter.collectionName + '/image',
            'png',
            id,
            item.image_url
          )
          adapter.update(id, {
            image_src: url,
          })
        }
      }}
      dashboardColumns={[
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
      ]}
    />
  )
}

export default MeditationCategoriesCRUD
