import { firestore } from 'firebase'
export interface FirestoreEntity {
  _enabled?: boolean
  _created_at?: firestore.Timestamp
  _last_updated_at?: firestore.Timestamp
}

export abstract class FirestoreCollectionAdapter<
  Entity extends FirestoreEntity = FirestoreEntity
> {
  public collection: firestore.CollectionReference<Entity>

  constructor(
    readonly collectionName: string,
    converter?: firestore.FirestoreDataConverter<Entity>
  ) {
    const collection = firestore().collection(collectionName)
    if (converter) {
      this.collection = collection.withConverter(converter)
    } else {
      this.collection = collection.withConverter<Entity>({
        fromFirestore: doc => doc.data() as Entity,
        toFirestore: (data: Entity) => data,
      })
    }
  }

  get = (id: string) => this.collection.doc(id).get()

  set = async (data: Entity) => {
    const doc = this.collection.doc()
    await doc.set({
      ...data,
      _enabled: data._enabled || true,
      _created_at: firestore.Timestamp.now(),
      _last_updated_at: firestore.Timestamp.now(),
    })
    return doc.id
  }

  update = async (id: string, data: Partial<Entity>) => {
    const writeData = { ...data }
    // If enabled is not defined in this update then don't overwrite
    if (writeData._enabled === undefined) delete writeData._enabled
    this.collection.doc(id).update({
      ...writeData,
      _last_updated_at: firestore.Timestamp.now(),
    })
  }
}
