import React from 'react'

import { useHistory } from 'react-router-dom'

import SearchResultComponent from 'src/components/SearchResultComponent'
import MeditationSearchCache from 'src/util/TEMP_MeditationSearchCache'
import styled from 'styled-components'

const SearchResult = styled.div`
  
  display: flex
  align-items: center

  & > img {
    height: 50px
    width: 50px
    object-fit: cover
    margin-right: .5rem
  }
`

export default function Search() {
  const history = useHistory()

  const [query, setQuery] = React.useState()

  async function searchMeditations(query) {
    if (!query || query.length < 2) return
    try {
      return await MeditationSearchCache.instance.findMeditationWithNameLike(
        query
      )
    } catch (e) {
      console.warn(e)
    }
  }

  return (
    <div>
      <SearchResultComponent
        query={query}
        onQueryChange={({ target }) => setQuery(target.value)}
        renderResult={el => (
          <SearchResult>
            <img src={el.cover_photo_url} />
            <span>{el.name}</span>
          </SearchResult>
        )}
        onSearch={searchMeditations}
        onResultSelect={med => {
          history.push(`edit/${med.id}`)
        }}
        inputProps={{
          size: 30,
          placeholder: 'Search for meditation by title',
        }}
      />
    </div>
  )
}
