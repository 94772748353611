import {
  Button,
  createStyles,
  FormHelperText,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import * as React from 'react'
import { isEmpty } from 'lodash'

export type DataPair = {
  key: string
  value: string
}

export interface PropertyListProps {
  properties: DataPair[]
  errors?: { key: string; value: string }[] // would be good as a mapped type of strings
  onItemUpdate: (data: DataPair, index: number) => void
  onAdd: () => void
  onDelete: (data: DataPair, index: number) => void
}

const PropertyList: React.FC<PropertyListProps> = ({
  properties,
  errors,
  onAdd,
  onDelete,
  onItemUpdate,
}) => {
  const classes = useStyles()

  return (
    <div>
      {properties?.map(({ key, value }, i) => {
        return (
          <div>
            <div className={classes.item}>
              <TextField
                onChange={({ target }) =>
                  onItemUpdate({ key: target.value, value }, i)
                }
                name={i + '-key'}
                value={key}
                variant='outlined'
                placeholder='Key'
              />
              <Typography>:</Typography>
              <TextField
                onChange={({ target }) =>
                  onItemUpdate({ value: target.value, key }, i)
                }
                name={i + '-value'}
                value={value}
                variant='outlined'
                placeholder='Value'
              />
              <IconButton onClick={() => onDelete({ key, value }, i)}>
                <Delete />
              </IconButton>
            </div>
            {!isEmpty(errors?.[i]) && (
              <FormHelperText error={true} children={errors![i].key} />
            )}
          </div>
        )
      })}
      <div>
        <Button variant='outlined' onClick={onAdd} startIcon={<Add />}>
          Add
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    item: {
      '&': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  })
)

export default PropertyList
