import { TextField, TextFieldProps } from '@material-ui/core'
import { FormikContext } from 'formik'
import React, { useContext } from 'react'
import { StyledInputLabel } from './styled-basics'

const FormikTextField = ({ name = '', label, ...props }: TextFieldProps) => {
  const { values, errors, handleChange } = useContext(FormikContext)

  return (
    <>
      <StyledInputLabel>{label}</StyledInputLabel>
      <TextField
        name={name}
        value={values[name]}
        onChange={handleChange}
        error={!!errors[name]}
        helperText={errors[name] ?? undefined}
        {...props}
      />
    </>
  )
}

export default FormikTextField
