import React from 'react'

import { Route, useRouteMatch, Switch } from 'react-router-dom'

import New from './New'

export default function () {
  let { path } = useRouteMatch()
  const toPath = end => `${path}${end}`

  return (
    <Switch>
      <Route exact path={path}>
        <h3>Select a tab to get started!</h3>
      </Route>
      <Route path={toPath('/new')}>
        <New />
      </Route>
    </Switch>
  )
}
