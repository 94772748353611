import React from 'react'

import Button from 'src/components/Button'
import { Link } from 'react-router-dom'

export default function LinkButton({ nofill = true, children, ...props }) {
  return (
    <Link {...props}>
      <Button nofill={nofill} children={children} />
    </Link>
  )
}
