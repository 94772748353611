import React from 'react'
import { toast } from 'react-toastify'

import { aaxios } from 'src/util/app-axios'
import { Dots } from 'src/components/spinners'
import useAuth from 'src/hooks/useAuth'

const Roles = () => {
  const [id, setId] = React.useState('')
  const [userInfo, setUserInfo] = React.useState(false)

  const [loading, setLoading] = React.useState(false)

  const auth = useAuth()

  const handleSubmit = async ev => {
    setLoading(true)
    ev.preventDefault()
    try {
      setUserInfo((await (await aaxios()).get('auth/' + id)).data.data)
    } catch (e) {
      toast.error('Error getting user: ' + e.response.data.error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateRole = async (uid, newRole) => {
    setLoading(true)
    try {
      await (await aaxios()).post('auth/' + uid + '/update-role', {
        role: newRole,
      })
      setUserInfo({
        ...userInfo,
        customClaims: {
          ...(userInfo.customClaims || {}),
          admin_role: newRole,
        },
      })
      toast.success('User updated successfully!')
    } catch (e) {
      toast.error('Error changing role: ' + e.response.data.error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <h4>Your Info</h4>
      <div>Your UID: {auth.user.uid}</div>
      <div>Your role: {auth.role}</div>

      <br />

      <h4>Search and Edit Roles</h4>
      <form onSubmit={handleSubmit}>
        <input
          type='search'
          value={id}
          onChange={({ target }) => setId(target.value)}
        />
        <input type='submit' />
      </form>
      {loading ? <Dots /> : <br />}
      {userInfo === false ? null : (
        <UserAuthInfo userInfo={userInfo} onUpdateRole={handleUpdateRole} />
      )}
    </div>
  )
}

function UserAuthInfo({ userInfo, onUpdateRole }) {
  const { customClaims = {}, email, displayName, uid } = userInfo
  const [newRole, setNewRole] = React.useState(
    customClaims.admin_role || 'none'
  )

  const handleUpdateRole = ev => {
    ev.preventDefault()
    onUpdateRole(userInfo.uid, newRole)
  }

  return (
    <div>
      <div>UID: {uid}</div>
      {!displayName ? null : <div>Name: {displayName}</div>}
      {!email ? null : <div>Email: {email}</div>}
      <div>Role: {customClaims.admin_role || 'None'}</div>
      <form onSubmit={handleUpdateRole}>
        Set Role
        <select
          value={newRole}
          onChange={({ target }) => {
            setNewRole(target.value)
          }}
        >
          <option value='none'>None</option>
          <option value='admin'>Admin</option>
          <option value='owner'>Owner</option>
        </select>
        <input type='submit' />
      </form>
    </div>
  )
}

export default Roles
