import firebase from 'firebase/app'

const imageTypes = ['image/png', 'image/jpeg']

const TYPES = {
  meditation_audio: {
    mimes: ['audio/mp3', 'audio/mpeg'],
    ext: '.mp3',
    collection: 'meditations',
    field: 'audio_src_url',
  },
  meditation_cover: {
    mimes: imageTypes,
    ext: '.jpg',
    collection: 'meditations',
    field: 'cover_photo_url',
  },
  author_avatar: {
    mimes: imageTypes,
    ext: '.jpg',
    collection: 'authors',
    field: 'author_avatar',
  },
}

export default class FileUploadService {
  static async uploadFile(type, id, file) {
    if (!Object.keys(TYPES).some(t => t === type))
      throw 'Please provide a valid file upload type'
    if (!TYPES[type].mimes.some(ext => ext === file.type))
      throw `File is not a valid format! (Must be one of: ${TYPES[type].mimes})`
    const storageRef = firebase.storage().ref()
    const ref = storageRef.child(`${type}/${id}${TYPES[type].ext}`)
    await ref.put(file)
    const url = await ref.getDownloadURL()
    await firebase
      .firestore()
      .collection(TYPES[type].collection)
      .doc(id)
      .update({
        [TYPES[type].field]: url,
      })
  }
}
