import { InputLabel } from '@material-ui/core'
import styled from 'styled-components'

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 5px;
`

export const StyledForm = styled.form`
  display: flex
  flex-direction: column
  align-items: flex-start

  ${StyledInputLabel}:not(:first-child) {
    margin-top: 20px;
  }
`
