import { Button, FormHelperText, Typography } from '@material-ui/core'
import { FormikConfig, FormikProvider, useFormik } from 'formik'
import { cloneDeep, merge } from 'lodash'
import * as React from 'react'
import AutoCompleteField from '../../material-x/formik/AutoComplete'
import SelectField from '../../material-x/formik/Select'
import TextInput from '../../material-x/formik/TextInput'
import ThemeColorPicker from '../../material-x/formik/ThemeColorPicker'
import ButtonActionFields from './ButtonAction'
import iconNames from './icon-names.json'
import schema, { BannerConfigurationInput } from './schema'
import './style.css'

const iconOptions = [''].concat(iconNames)

export interface BannerConfigFormProps {
  initialData: Partial<BannerConfigurationInput> | {}
  onSubmit: FormikConfig<BannerConfigurationInput>['onSubmit']
  // onFormDataSet: (resetForm: (ReturnType<(typeof useFormik)>)['resetForm']) => void
}

const FormSectionHeading = ({ title = '', desc = '' }) => (
  <>
    {title.length > 0 && (
      <Typography
        style={{ marginTop: 20 }}
        variant='overline'
        color='primary'
        children={title}
      />
    )}
    {desc.length > 0 && (
      <Typography
        variant='subtitle2'
        color='textSecondary'
        children={desc}
        gutterBottom
      />
    )}
  </>
)

export const defaultValues: FormikConfig<
  BannerConfigurationInput
>['initialValues'] = {
  size: 'medium',
  title: '',
  text: '',
  backgroundColor: '#606060',
  // Button
  buttonText: '',
  buttonIcon: '',
  buttonBackgroundColor: '#ffffff',
  buttonColor: '#ec008c',
  // @ts-expect-error THIS IS JUST INITIAL i dont understand
  buttonAction: undefined,
  imageSrc: '',
  backgroundSrc: '',
  // Additional options
  options: {
    imagePosition: 'left',
    orderType: 'after-row',
    afterRow: 1,
    minVersion: undefined,
  },
}

const BannerConfigForm: React.FC<BannerConfigFormProps> = ({
  // onFormDataSet,
  initialData,
  onSubmit,
}) => {
  const formik = useFormik<BannerConfigurationInput>({
    initialValues: merge(cloneDeep(defaultValues), initialData),
    validationSchema: schema,
    onSubmit: onSubmit,
  })

  const { values, submitForm, setValues } = formik

  React.useEffect(() => {
    const data = merge(cloneDeep(defaultValues), initialData)
    setValues(data)
  }, [initialData])

  return (
    <FormikProvider value={formik}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextInput label='Title (Optional)' name='title' />
        <TextInput label='Text (Optional)' name='text' multiline={true} />
        <SelectField label='Size' name='size' options={['medium', 'large']} />

        <FormSectionHeading
          title='Background'
          desc='Properties for background. If image cannot be found, color will be the fallback. If neither are provided, a default color will be used.'
        />
        <ThemeColorPicker label='Background Color' name='backgroundColor' />
        <TextInput label='Background URL (Optional)' name='backgroundSrc' />

        <FormSectionHeading
          title='Image'
          desc='Properties for image. If no url is provided, then no image will be shown.'
        />
        <TextInput label='Image URL (Optional)' name='imageSrc' />
        {values.imageSrc && (
          <SelectField
            label='Image Position'
            name='options.imagePosition'
            options={['left', 'right']}
          />
        )}

        <FormSectionHeading
          title='Button Config'
          desc='Button text and action must be provided otherwise the button will not be shown.'
        />
        <TextInput label='Button Text (Optional)' name='buttonText' />
        <AutoCompleteField
          label='Button Icon'
          name='buttonIcon'
          options={iconOptions}
        />
        <FormHelperText>
          Icon will show next to text on button. See{' '}
          <a href='https://ionic.io/ionicons' target='_blank'>
            Ionicons
          </a>{' '}
          to view at all available icons.
        </FormHelperText>

        <ThemeColorPicker
          label='Button Background Color'
          name='buttonBackgroundColor'
        />
        <ThemeColorPicker label='Button Text Color' name='buttonColor' />
        <ButtonActionFields baseProperty='buttonAction' />

        <FormSectionHeading title='Additional Options' />
        <SelectField
          label='Order Type'
          name='options.orderType'
          options={['above-highlighted', 'below-highlighted', 'after-row']}
        />
        {values.options.orderType === 'after-row' && (
          <TextInput label='After Row' name='options.afterRow' />
        )}
        <TextInput
          label='Minimum Version (Optional)'
          name='options.minVersion'
        />

        {/* Submit Button */}
        <Button
          style={{ marginTop: 16 }}
          onClick={submitForm}
          color='primary'
          variant='contained'
        >
          Save
        </Button>
      </form>
    </FormikProvider>
  )
}

export default BannerConfigForm
