import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import Button from 'src/components/Button'
import useForm, { Form, Input, useValidation } from 'src/hooks/useForm'
import useAuth from 'src/hooks/useAuth'

const ScreenContainer = styled.div`
  width: 100%
  display: flex
  justify-content: center
`

const FormContainer = styled(Form)`
  display: flex
  flex-direction: column
  border: 1px solid grey
  border-radius: .25rem
  padding: 1rem
  margin-top: 2rem
`

const StyledInput = styled(Input)`
  margin-bottom: 0.75rem;
`

export default function Login() {
  const auth = useAuth()

  const submit = async values => {
    try {
      await auth.signIn(values.email, values.password)
      toast.success('Logged in successfully!')
    } catch (e) {
      toast.error('Error logging in: ' + e.message)
    }
  }

  const validate = useValidation({
    email: ['email'],
    password: ['password'],
  })

  const formState = useForm(
    {
      email: '',
      password: '',
    },
    submit,
    validate,
    {
      enterToSubmit: true,
    }
  )

  return (
    <ScreenContainer>
      <FormContainer state={formState}>
        <StyledInput name='email' placeholder='Email' size='30' />
        <StyledInput
          name='password'
          type='password'
          placeholder='Password'
          size='30'
        />
        <Button onClick={formState.handleSubmit}>Login</Button>
      </FormContainer>
    </ScreenContainer>
  )
}
