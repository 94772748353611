import firebase, { firestore } from 'firebase'
import * as React from 'react'
import { toast } from 'react-toastify'
import { defaultValues } from 'src/components/forms/BannerConfig/Form'
import { AlertDialog } from 'src/components/material-x/dialogs'
import {
  deleteBanner,
  getAllBannerConfigurations,
  loadBannerDataIntoForm,
  prepareBannerDataForFirestore,
  saveBannerConfiguration,
  setBannerVisiblity,
} from 'src/services/BannerConfigService'
import { BannerConfigurationDocument } from 'src/types'
import BannersDashboardView, {
  BannersDashboardViewProps,
} from './DashboardView'
import usePendingCallback from './usePendingCallback'

export interface BannersDashboardProps {}

const DISCARD_CONFIRMATION = {
  text: 'You have an unsaved configuration, are you sure you want to continue?',
  continue: 'Discard & Continue',
}

const BannersDashboard: React.FC<BannersDashboardProps> = () => {
  // Banner data
  const [configList, setConfigList] = React.useState<
    BannerConfigurationDocument[] | undefined
  >(undefined)

  React.useEffect(() => {
    getAllBannerConfigurations().then(data => {
      setConfigList(data)
    })
  }, [])

  // Selected Item
  const [selectedConfig, setSelectedConfig] = React.useState<
    BannerConfigurationDocument | {}
  >()
  // HACK: should make this make more sense, this is dumb
  const [selectedId, setSelectedId] = React.useState<string>()

  const [actionState, dispatch] = usePendingCallback()

  // Get's the id of a requested config or shows and alert and returns false otherwise
  function getIdOrFalse(configs: typeof configList, index: number) {
    if (!configs || !configs[index].uid) {
      toast.error('Error: Item is invalid')
      return false
    }
    return configs[index].uid
  }

  // Callback Handlers
  const handleItemClick: BannersDashboardViewProps['onItemClick'] = item => {
    dispatch({
      type: 'request',
      callback: () => {
        const data = loadBannerDataIntoForm(item.configuration)
        setSelectedConfig(data)
        setSelectedId(item.uid!)
      },
      autoConfirm: !selectedConfig,
      confirmation: DISCARD_CONFIRMATION,
    })
  }

  const handleCreateClick = () => {
    dispatch({
      type: 'request',
      callback: () => {
        setSelectedConfig(defaultValues)
        setSelectedId(undefined)
      },
      autoConfirm: !selectedConfig,
      confirmation: DISCARD_CONFIRMATION,
    })
  }

  const handleSaveSelectedItem = React.useCallback(
    values => {
      const data = prepareBannerDataForFirestore(values)
      const { currentUser } = firebase.auth()
      // FIXME: i dont thing the last_update_<> items should be handled here
      const newDocument = {
        configuration: data,
        last_updated_by: currentUser?.email ?? 'Unknown',
        last_updated_at: firestore.Timestamp.now(),
        enabled: false,
        uid: selectedId || null,
      }
      saveBannerConfiguration(newDocument)
        .then(() => {
          toast.success('The banner was added successfully!')
          if (selectedId && configList) {
            setConfigList(
              configList.map(doc => {
                if (doc.uid !== selectedId) return doc
                return newDocument
              })
            )
          }
        })
        .catch(e => {
          toast.error(
            'There was an error saving this configuration: ' + e.message
          )
        })
    },
    [selectedId, configList]
  )

  const handleToggleVisiblity = (enabled: boolean, i: number) => {
    if (!getIdOrFalse(configList, i)) return
    dispatch({
      type: 'request',
      callback: async () => {
        const id = getIdOrFalse(configList, i)
        if (!id) return
        try {
          // Set db vis
          await setBannerVisiblity(id, enabled)
          // Update client-side list
          setConfigList(
            configList!.map((item, li) => {
              if (li === i)
                return {
                  ...item,
                  enabled,
                }
              return item
            })
          )
          toast.success(`Item is now ${enabled ? 'en' : 'dis'}abled.`)
        } catch (e) {
          toast.error('An error occurred setting visibility.')
        }
      },
    })
  }

  const handleDeleteClick = (i: number) => {
    if (!getIdOrFalse(configList, i)) return
    dispatch({
      type: 'request',
      callback: async () => {
        const id = getIdOrFalse(configList, i)
        if (!id) return
        try {
          // Delete banner in backend
          await deleteBanner(id)
          // Update client-side list
          setConfigList(configList!.filter((_, ci) => ci !== i))
          toast.success('Item deleted successfully.')
        } catch (e) {
          toast.success('An error occurred deleting this item.')
        }
      },
    })
  }

  return (
    <>
      <BannersDashboardView
        data={configList}
        selectedItem={selectedConfig}
        onItemClick={handleItemClick}
        onCreateClick={handleCreateClick}
        onSaveSelectedItem={handleSaveSelectedItem}
        onToggleVisibilityClick={handleToggleVisiblity}
        onDeleteClick={handleDeleteClick}
      />
      <AlertDialog
        open={!!actionState.pendingCallback}
        onClose={() => dispatch({ type: 'cancel' })}
        title='Confirm Action'
        text={
          actionState.confirmation?.text ?? 'Are you sure you want to continue?'
        }
        outcomes={[
          {
            action: () => dispatch({ type: 'cancel' }),
            text: 'Cancel',
          },
          {
            action: () => dispatch({ type: 'confirm' }),
            text: actionState.confirmation?.continue ?? 'Continue',
            autoFocus: true,
          },
        ]}
      />
    </>
  )
}

export default BannersDashboard
