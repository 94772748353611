import { rules as defaultRules } from './defaultRules'
import { useMemo, useCallback } from 'react'

function getFirstResult(arr, value) {
  var result
  for (const elem of arr) {
    result = elem(value)
    if (result !== undefined) return result
  }
  return result
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

// Returns the set of all distinct validators for a given rule
function getValidators(rule, mapping) {
  return resolveRule(rule, mapping).filter(onlyUnique)
}

// Recursively finds all elements which a rule composes
function resolveRule(rule, mapping) {
  return rule.flatMap(elem => {
    // Value is a validator
    if (elem instanceof Function) return [elem]
    // Value is a reference to another rule
    else return resolveRule(mapping[elem], mapping)
  })
}

// === Terms ===
// Rule: composition (array) of any amount of validators and/or references to other rules
// Validator: function which return error state depending given value
// Validator Stack/Set: Array of unique validator functions which, when run,
//    error priority is highest at the start of the array
// =============

export function useValidation(keyRuleSet, baseRules = defaultRules) {
  // Memoizes an Object containing each key's validation set
  const keyValidators = useMemo(() => {
    const v = {}
    for (const key in keyRuleSet) {
      v[key] = getValidators(keyRuleSet[key], baseRules)
    }
    return v
  }, [baseRules, keyRuleSet])

  // Returns callback function which gets errors based on value
  // Perfect for use with useForm but can be used elsewhere too
  return useCallback(
    values => {
      const errors = {}
      for (const key in values) {
        //skip values which have no validators
        if (!(key in keyValidators)) continue
        const e = getFirstResult(keyValidators[key], values[key])
        if (e) errors[key] = e
      }
      return errors
    },
    [keyValidators]
  )
}

export { rules } from './defaultRules'
