import { FieldMetaProps } from 'formik'
import { useMemo } from 'react'

export const isFieldError = (meta: FieldMetaProps<any>) =>
  !!(meta.error && meta.touched)

export const useFieldExtensions = (meta: FieldMetaProps<any>) => {
  const values = useMemo(
    () => ({
      error: isFieldError(meta),
    }),
    [meta]
  )
  return values
}
