import { Input, InputProps } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
//@ts-ignore
import styled from 'styled-components'

const ImagePreview = styled.img`
  height: 80px
  min-width: 80px
  background-color: #ddd
  margin-bottom: .5rem
`

const AudioPreview = styled.audio`
  margin-bottom: 0.5rem;
`

type FilePreviewSource = ArrayBuffer | string | undefined | null

async function convertBlobToDataURL(
  fileData: File
): Promise<FilePreviewSource> {
  return new Promise(resolve => {
    if (fileData instanceof Blob) {
      const reader = new FileReader()
      reader.onload = ({ target }) => {
        resolve(target?.result)
      }
      reader.readAsDataURL(fileData)
    } else resolve(undefined)
  })
}

interface ImageInput extends InputProps {
  file: File
  previewType?: 'image' | 'audio' | 'none'
  style?: React.CSSProperties
  inputStyle?: InputProps['style']
}

const FileInput: React.FC<ImageInput> = ({
  file,
  previewType = 'image',
  style = {},
  inputStyle = {},
  ...props
}) => {
  const [filePreview, setFilePreview] = useState<FilePreviewSource>()

  useEffect(() => {
    if (file) {
      if (file instanceof File) {
        convertBlobToDataURL(file).then(dataUrl => {
          setFilePreview(dataUrl)
        })
        return
      } else if (typeof file === 'string') {
        setFilePreview(file)
        return
      }
    }
    setFilePreview(null)
  }, [file])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...style,
      }}
    >
      {previewType === 'image' && <ImagePreview src={filePreview} />}
      {previewType === 'audio' && <AudioPreview controls src={filePreview} />}
      <Input type='file' style={inputStyle} {...props} />
    </div>
  )
}

export default FileInput
