import { FormControl, Typography } from '@material-ui/core'
import { useField } from 'formik'
import * as React from 'react'
import { array, object, string } from 'yup'
import PropertyList, { DataPair } from '../PropertyList'
import { MaterialXFormikInputBase } from './types'

export type PropertyListFieldProps = MaterialXFormikInputBase

export const yupDataPair = () =>
  array().of(
    object({
      key: string().required(),
      value: string(),
    }).required()
  )

const PropertyListField: React.FC<PropertyListFieldProps> = ({
  label,
  name,
}) => {
  const [field, meta, { setValue }] = useField<DataPair[]>(name)
  // const ext = useFieldExtensions(meta)

  const value = field.value ?? []

  return (
    <FormControl>
      <Typography children={label} color='textSecondary' />
      <PropertyList
        properties={value}
        //@ts-ignore TODO revisit; this things error is string |undefined, even though it is an array of objects
        errors={meta.error}
        onItemUpdate={(pair, index) => {
          const newData = [...value]
          newData[index] = pair
          setValue(newData)
        }}
        onDelete={(pair, index) => {
          setValue(value.filter((e: any, i: number) => i !== index))
        }}
        onAdd={() => setValue([...value, { key: '', value: '' }])}
      />
    </FormControl>
  )
}

export default PropertyListField
