import isEmail from 'validator/lib/isEmail'

export const rules = {
  required: [
    v => {
      if (!v) return 'Required'
    },
  ],
  email: [
    'required',
    v => {
      if (!isEmail(v)) return 'Not a valid email'
    },
  ],
  password: ['required'],
  required_file: [
    v => {
      if (!(v instanceof File)) return 'File is required'
    },
  ],
  image_file: [
    v => {
      if (v && !v.type.match(/^image\/(png|jpeg)$/))
        return 'File must be a PNG or JPEG'
    },
  ],
  audio_file: [
    v => {
      if (v && !(v.type === 'audio/mp3' || v.type === 'audio/mpeg'))
        return 'File must be an MP3'
    },
  ],
}
