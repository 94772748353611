import { TextField } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { useField } from 'formik'
import * as React from 'react'
import { SketchPicker } from 'react-color'
import { useFieldExtensions } from './util'

export interface ThemeColorPickerProps {
  name: string
  label: string
}

const ThemeColorPicker: React.FC<ThemeColorPickerProps> = ({ name, label }) => {
  const [field, meta, { setValue }] = useField(name)
  const ext = useFieldExtensions(meta)
  const [pickerOpen, setPickerOpen] = React.useState(false)

  return (
    <div
      className='MuiFormControl-root'
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
    >
      <TextField {...field} label={label} {...ext} helperText={meta.error} />
      <span
        onClick={e => {
          e.stopPropagation()
          setPickerOpen(!pickerOpen)
        }}
        style={{
          height: 24,
          width: 24,
          borderRadius: 4,
          backgroundColor: field.value,
          alignSelf: 'flex-end',
          position: 'relative',
          border: '1px solid #111',
          marginLeft: 6,
        }}
      ></span>
      {pickerOpen ? <ChevronLeft /> : <ChevronRight />}
      <span
        style={{
          position: 'relative',
          marginTop: 8,
          alignSelf: 'flex-start',
        }}
      >
        <span
          style={{
            display: pickerOpen ? 'initial' : 'none',
            position: 'absolute',
            zIndex: 200,
          }}
        >
          <SketchPicker
            disableAlpha={true}
            color={field.value}
            onChange={({ hex }) => setValue(hex)}
            presetColors={['#ec008c', '#fc6767', '#ffb54d', '#7f39b0']}
          />
        </span>
      </span>
    </div>
  )
}

export default ThemeColorPicker
