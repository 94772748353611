import { omit } from 'lodash'
import React, { useRef } from 'react'
import MeditationTagForm, {
  MeditationTagFormData,
} from 'src/components/forms/tags'
import { MeditationTag, MeditationTagAdapter } from 'src/entities/MeditationTag'
import CRUDMaker from 'src/pages/crud-maker'

const MeditationTagsCRUD = () => {
  const adapter = useRef(new MeditationTagAdapter()).current
  return (
    <CRUDMaker<MeditationTagFormData, MeditationTag>
      entityName='Meditation Tags'
      Form={MeditationTagForm}
      adapter={adapter}
      onSubmit={async (item, id) => {
        if (!id) {
          id = await adapter.set(item)
        } else {
          await adapter.update(id, {
            name: item.name,
            description: item.description,
            _enabled: item._enabled,
          })
        }
      }}
      dashboardColumns={[
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
      ]}
    />
  )
}

export default MeditationTagsCRUD
