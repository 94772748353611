import { firestore } from 'firebase'
import { FirestoreEntity, FirestoreCollectionAdapter } from './base'

export interface Meditation extends FirestoreEntity {
  name: string
  description?: string
  author: firestore.DocumentReference
  categories?: firestore.DocumentReference[]
  growth_points?: Record<string, number>
  is_premium: boolean
  cover_photo_url?: string
  audio_src_url?: string
  display_time?: string
  enabled: boolean
  tags?: firestore.DocumentReference[]
  mood_categories: firestore.DocumentReference[]
  suit_categories: firestore.DocumentReference[]
  created_at?: firestore.Timestamp
}

export class MeditationAdapter extends FirestoreCollectionAdapter<Meditation> {
  constructor() {
    super('meditations')
  }
}
