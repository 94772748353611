/*
- Action to be executed
- Current action state (is pending, message if pending, etc. (could be interfaceable))
*/

import { useReducer } from 'react'

// const oldReducer = (
//   state: {
//     pendingAction: (() => void) | null
//   },
//   {
//     type,
//     currentSelectedConfig,
//     action,
//   }: {
//     type: 'request' | 'confirm' | 'cancel'
//     currentSelectedConfig?: any
//     action?: () => void
//   }
// ) => {
//   if (type === 'cancel')
//     return {
//       pendingAction: null,
//     }
//   if (type === 'request' && !!currentSelectedConfig) {
//     console.log('we in request')
//     if (!action) throw new Error('No action provided with request')
//     return {
//       pendingAction: action,
//     }
//   }

//   const actionToCall = type === 'request' ? action : state.pendingAction
//   if (!actionToCall) throw new Error('No valid action was found')
//   else actionToCall()

//   return { pendingAction: null }
// }

interface PendingCallbackState {
  pendingCallback: (() => void) | null
  // HACK: should be abstracted (should be able to add whatever 'extra' data needed)
  confirmation?: {
    text: string
    continue: string
  }
}

interface PendingCallbackAction {
  type: 'request' | 'confirm' | 'cancel'
  // HACK: should be abstracted (see above)
  confirmation?: {
    text: string
    continue: string
  }
  //Used so that conditional logic can determine an immediate return of an action request
  autoConfirm?: boolean
  callback?: () => void
}

const reducer = (
  state: PendingCallbackState,
  { type, callback, confirmation, autoConfirm = false }: PendingCallbackAction
) => {
  // Reset state if action type is cancel
  if (type === 'cancel')
    return {
      pendingCallback: null,
    }
  // When action type is request, track the pending Action in the state
  if (type === 'request') {
    if (!callback) throw new Error('No action provided with request')
    if (autoConfirm) {
      callback()
      return { pendingCallback: null }
    }
    return {
      pendingCallback: callback,
      confirmation,
    }
  }

  // This ultimately means type === 'confirm'; invokes callback and resets state
  if (!state.pendingCallback) throw new Error('No valid action was found')
  else state.pendingCallback()

  return { pendingCallback: null }
}

const usePendingCallback = () =>
  useReducer(reducer, {
    pendingCallback: null,
  })

export default usePendingCallback
