import { cyup, fileTypeSets } from 'src/util/validation'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  name: yup.string().required().min(1),
  description: yup.string(),
  background_color: yup.string().required().min(1),
  image_url: cyup.ensureFile({ validFileTypes: fileTypeSets.firebaseImage }),
})

export default validationSchema
