import { TextField, TextFieldProps } from '@material-ui/core'
import { useField } from 'formik'
import * as React from 'react'
import { useFieldExtensions } from './util'

export type TextInputProps = {
  name: string
  label: string
} & TextFieldProps

const TextInput: React.FC<TextInputProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name)
  const ext = useFieldExtensions(meta)

  return (
    <TextField
      helperText={ext.error && meta.error}
      {...field}
      {...ext}
      {...props}
    />
  )
}

export default TextInput
