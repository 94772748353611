import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import firebase, { firestore } from 'firebase/app'
import moment from 'moment'
import { toast } from 'react-toastify'

import SearchResultComponent from 'src/components/SearchResultComponent'
import { Dots } from 'src/components/spinners'
import { Column, Row } from 'src/components/simple-responsive'
import MeditationSearchCache from 'src/util/TEMP_MeditationSearchCache'

const StyledCalendar = styled(Calendar)`
  & .filled {
    background-color: #00f1;
  }
`

const toDaily = d => moment(d).format('MM-DD-YYYY')

const Meditation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 4rem;

  & > img {
    height: 4rem;
    width: 4rem;
    margin-right: 0.5rem;
  }

  & .textContainer {
    display: flex;
    flex-direction: column;
  }

  & .textContainer > *:first-child {
    font-weight: bold;
  }
`

function DailyMeditation({ date, ...props }) {
  const [meditation, setMeditation] = useState()
  const [author, setAuthor] = useState('Author Loading')

  useEffect(() => {
    setMeditation(undefined)
    setAuthor(undefined)

    return firebase
      .firestore()
      .collection('daily_metadata')
      .doc(toDaily(date))
      .onSnapshot(async doc => {
        if (doc.exists) {
          const meditationDoc = await doc.get('meditation').get()
          if (meditationDoc.exists) {
            const data = meditationDoc.data()
            const authorDoc = await data.author.get()
            if (authorDoc.exists) setAuthor(authorDoc.get('name'))
            setMeditation(data)
            return
          }
        }
        setMeditation(null)
      })
  }, [date])

  return (
    <Meditation {...props}>
      {(() => {
        // resolve what should be shown based on meditation value
        switch (meditation) {
          case undefined:
            return (
              <span>
                <Dots />
                <span> Loading...</span>
              </span>
            )
          case null:
            return (
              <span>
                No meditation is set for{' '}
                {moment(date).format('dddd, MMMM Do YYYY')}
              </span>
            )
          default:
            return (
              <>
                <img src={meditation.cover_photo_url} alt='meditation cover' />
                <div className='textContainer'>
                  <span>{meditation.name}</span>
                  <span>{author}</span>
                  <span>
                    Is Premium? {meditation.is_premium ? 'Yes' : 'No'}
                  </span>
                </div>
              </>
            )
        }
      })()}
    </Meditation>
  )
}

async function setDailyMeditationInFirestore(date, ref) {
  await firebase
    .firestore()
    .collection('daily_metadata')
    .doc(toDaily(date))
    .set({
      meditation: ref,
      date: firebase.firestore.Timestamp.fromDate(date),
    })
}

export default function SetDailyMeditation() {
  const [date, setDate] = useState(new Date())
  const [query, setQuery] = useState('')

  const handleMeditationSelect = async el => {
    try {
      await setDailyMeditationInFirestore(date, el.ref)
      // add meditation to dailies
      setDailies({
        ...dailies,
        [toDaily(date)]: {
          data: el,
          date,
        },
      })
      toast.success('Daily Meditation successfully set!')
      setQuery('')
    } catch (e) {
      console.warn(e)
      toast.error('There was an error setting the dailty meditation!')
    }
  }

  const handleSearch = async q => {
    const results = await MeditationSearchCache.instance.findMeditationWithNameLike(
      q,
      15
    )
    return results.map(m => ({
      ...m,
      ref: firestore().collection('meditations').doc(m.id),
    }))
  }

  // Get daily meditations around the current date
  // Data used to inform user which days have been set already
  const [dailies, setDailies] = useState({})
  useEffect(() => {
    const { Timestamp } = firebase.firestore
    firebase
      .firestore()
      .collection('daily_metadata')
      .where(
        'date',
        '>=',
        Timestamp.fromDate(moment().subtract(1, 'months').toDate())
      )
      .where(
        'date',
        '<=',
        Timestamp.fromDate(moment().add(1, 'months').toDate())
      )
      .get()
      .then(async res => {
        const dailyMap = {}
        await Promise.all(
          res.docs.map(async res => {
            const date = res.get('date').toDate()
            dailyMap[toDaily(date)] = {
              data: (await res.get('meditation').get()).data(),
              date: date,
            }
          })
        )
        setDailies(dailyMap)
      })
  }, [])

  const setTileClassName = ({ date: tileDate, view }) => {
    if (
      view === 'month' &&
      toDaily(tileDate) in dailies &&
      !moment(date).isSame(moment(tileDate), 'day')
    )
      return 'filled'
    return null
  }

  return (
    <div>
      <p>
        Select a day on the calendar to get started. Search and select a
        meditation to set it as the daily meditation for the selected date. Days
        highlighted in light blue already have a daily meditation set.
        <span style={{ fontStyle: 'italic' }}>
          {' '}
          Note: Highlighting currently only occurs for up to 1 month before and
          after the current date.
        </span>
      </p>

      <Row>
        <Column>
          <StyledCalendar
            onChange={d => setDate(d)}
            value={date}
            tileClassName={setTileClassName}
            calendarType='US'
          />
        </Column>

        <Column>
          <DailyMeditation date={date} />
          <br />
          <SearchResultComponent
            query={query}
            onQueryChange={({ target }) => setQuery(target.value)}
            renderResult={el => <span>{el.name}</span>}
            onSearch={handleSearch}
            onResultSelect={handleMeditationSelect}
            inputProps={{
              size: 30,
              placeholder: 'Search for meditation by title',
            }}
          />
          <div style={{ fontStyle: 'italic' }}>
            Note: Search is case sensitive.
          </div>
        </Column>
      </Row>
    </div>
  )
}
