import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type DialogBaseProps = DialogProps & { onClose: () => void }

export const AlertDialog: React.FC<
  DialogBaseProps & {
    title: string
    text: string
    outcomes: { action: () => void; text: string; autoFocus?: true }[]
  }
> = ({ title, text, open, onClose, outcomes }) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {outcomes.map(({ action, text, autoFocus }) => (
            <Button onClick={action} color='primary' autoFocus={autoFocus}>
              {text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  )
}
