import { Button } from '@material-ui/core'
import { firestore } from 'firebase'
import {
  FormikContext,
  FormikProps,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import CustomFormikInput, {
  CustomFormikInputProps,
} from 'src/components/form-components/CustomFormikInput'
import FormikCheckbox from 'src/components/form-components/FormikCheckbox'
import FormikTextField from 'src/components/form-components/FormikTextField'
import FileInput from 'src/components/form-components/FileInput'
import { StyledForm } from 'src/components/form-components/styled-basics'
import { FirestoreCollectionAdapter, FirestoreEntity } from 'src/entities/base'
import { GrowthCategory } from 'src/entities/enums'
import { Meditation } from 'src/entities/Meditation'
import { MeditationCategoryAdapter } from 'src/entities/MeditationCategory'
import SimpleSelect from '../../form-components/SimpleSelect'
import { CRUDFormProps } from '../types'
import validationSchema from './validation'
//@ts-expect-error
import styled from 'styled-components'
import MultiSelect, { SelectedItem } from 'src/components/MultiSelect'
import { cloneDeep, initial } from 'lodash'
import ChipMultiSelect from 'src/components/ChipMultiSelect'
import { MeditationTagAdapter } from 'src/entities/MeditationTag'
import SearchResultComponent from 'src/components/SearchResultComponent'
import { Link } from 'react-router-dom'
import { MoodCategoryAdapter } from 'src/entities/MoodCategory'
import { SuitCategoryAdapter } from 'src/entities/SuitCategory'

type AsFiles<T, Files extends keyof T> = {
  [P in Files]: File | string
} &
  Omit<T, Files>

type FormikifyFirestore<T> = {
  [P in keyof T]: T[P] extends firestore.DocumentReference ? string : T[P]
}

type Pick<T, K extends keyof T> = {
  [P in K]: T[P]
}

export interface MeditationFormData
  extends Omit<
    FormikifyFirestore<
      AsFiles<Meditation, 'audio_src_url' | 'cover_photo_url'>
    >,
    | 'growth_points'
    | 'categories'
    | 'tags'
    | 'mood_categories'
    | 'suit_categories'
  > {
  growth_points: {
    key: string
    data: number
    label: string
  }[]
  categories: {
    key: string
    label: string
  }[]
  tags: {
    key: string
    label: string
  }[]
  mood_categories: {
    key: string
    label: string
  }[]
  suit_categories: {
    key: string
    label: string
  }[]
}

const growthCategoryEnum = {
  social_ease: 'Social Ease',
  openness: 'Openness',
  diplomacy: 'Diplomacy',
  leadership: 'Leadership',
  vision: 'Vision',
  responsibility: 'Responsibility',
  organization: 'Organization',
  self_confidence: 'Self Confidence',
  independence: 'Independence',
  creativity: 'Creativity',
  stress_management: 'Stress Management',
  responsiveness: 'Responsiveness',
  patience: 'Patience',
  determination: 'Determination',
  ambition: 'Ambition',
  work_ethic: 'Work Ethic',
}

const allGrowthCategories = Object.entries(growthCategoryEnum).map(
  ([key, name]) => ({
    key,
    data: 0,
    label: name,
  })
)

const StyledMultiSelect = styled(MultiSelect)`
  & .selected-list-container {
    min-width: 240px;
  }

  & .select-dropdown-container {
    min-width: 240px;
  }
`

const getFormValuesFromInitialMeditation = (
  initialMeditation?: Meditation
): MeditationFormData => ({
  name: initialMeditation?.name || '',
  description: initialMeditation?.description,
  author: initialMeditation?.author.id ?? '',
  // categories: (initialMeditation?.categories ?? []).map(ref => ({
  //   key: ref.id,
  //   label: '',
  // })),
  // @ts-expect-error
  growth_points: Object.keys(initialMeditation?.growth_points ?? {})
    .map(k => allGrowthCategories.find(g => g.key === k))
    .filter(x => x != undefined)
    .map(gp => {
      const inputGrowth = initialMeditation?.growth_points?.[gp!.key]
      if (inputGrowth) gp!.data = inputGrowth
      return gp
    }),
  categories: [],
  // growth_points: [],
  is_premium: initialMeditation?.is_premium ?? false,
  cover_photo_url: initialMeditation?.cover_photo_url ?? '',
  audio_src_url: initialMeditation?.audio_src_url ?? '',
  display_time: initialMeditation?.display_time,
  enabled: initialMeditation?.enabled ?? true,
  _enabled: initialMeditation?.enabled ?? true,
  // tags: initialMeditation?.tags ?? [],
  tags: [],
  mood_categories: [],
  suit_categories: [],
  created_at: initialMeditation?.created_at,
})

function useLoadAllAdapterOptions<T extends FirestoreEntity, U>(
  adapter: FirestoreCollectionAdapter<T>,
  mapper: (item: { id: string } & T) => U
) {
  const [options, setOptions] = useState<U[]>()

  useEffect(() => {
    adapter.collection.onSnapshot(x =>
      setOptions(x.docs.map(d => ({ id: d.id, ...d.data() })).map(mapper))
    )
  }, [])

  return options
}

function useLoadInitialItems<
  T extends { id: string },
  V extends { key: string }
>(
  initialValues: T[] | undefined,
  options: V[] | undefined,
  valueName: string,
  formik: FormikProps<any>
) {
  const { values, setFieldValue } = formik
  useEffect(() => {
    if (
      options &&
      !values?.[valueName].length &&
      (initialValues?.length ?? 0) > 0
    ) {
      const selected = initialValues!
        .map(elem => options.find(opt => opt.key === elem.id))
        .filter(x => x != undefined)
      setFieldValue(valueName, selected)
    }
  }, [options])
}

const mapMultiselectOption = (data: any) => ({
  key: data.id,
  label: data.name,
  data,
})

const FormikMultiSelect = ({
  options,
  ...other
}: {
  name: string
  label: string
  options?: any[]
}) => (
  <CustomFormikInput {...other}>
    {({ name, value }, { setFieldValue }) => {
      return (
        <StyledMultiSelect
          selected={value}
          options={options}
          onSelectionChange={(selected: any) => {
            setFieldValue(name, selected)
          }}
        />
      )
    }}
  </CustomFormikInput>
)

const MeditationForm = ({
  item,
  onSubmit,
}: CRUDFormProps<Meditation, MeditationFormData>) => {
  const formik = useFormik({
    initialValues: getFormValuesFromInitialMeditation(item),
    validationSchema,
    onSubmit,
  })
  const { handleSubmit, values, setFieldValue, errors } = formik

  const categoryOptions = useLoadAllAdapterOptions(
    new MeditationCategoryAdapter(),
    mapMultiselectOption
  )

  const tagOptions = useLoadAllAdapterOptions(
    new MeditationTagAdapter(),
    data => ({
      key: data.id,
      label: data.name,
    })
  )

  const moodOptions = useLoadAllAdapterOptions(
    new MoodCategoryAdapter(),
    mapMultiselectOption
  )

  const suitOptions = useLoadAllAdapterOptions(
    new SuitCategoryAdapter(),
    mapMultiselectOption
  )

  useLoadInitialItems(item?.categories, categoryOptions, 'categories', formik)
  useLoadInitialItems(item?.tags, tagOptions, 'tags', formik)
  useLoadInitialItems(
    item?.mood_categories,
    moodOptions,
    'mood_categories',
    formik
  )
  useLoadInitialItems(
    item?.suit_categories,
    suitOptions,
    'suit_categories',
    formik
  )

  const [authorName, setAuthorName] = useState<string>()
  const [authorQuery, setAuthorQuery] = useState<string>('')

  const handleAuthorSearch = async (query: string) => {
    const snapshot = await firestore()
      .collection('authors')
      .where('name', '>=', query)
      .limit(10)
      .get()
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
  }

  useEffect(() => {
    if (item?.author) {
      firestore()
        .collection('authors')
        .doc(item.author.id)
        .get()
        .then(doc => {
          setAuthorName(doc.data()?.name ?? 'Unknown')
        })
    }
  }, [item])

  console.log(errors)

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <FormikCheckbox name='_enabled' label='Enabled' />

        <FormikTextField name='name' label='Name' />

        <FormikTextField
          style={{ width: 300 }}
          name='description'
          label='Description'
        />

        <FormikCheckbox name='is_premium' label='Premium' />

        <CustomFormikInput name='author' label='Author'>
          {({ name, value, error }, { setFieldValue }) => (
            <>
              {authorName ?? null}
              <SearchResultComponent
                query={authorQuery}
                onQueryChange={({ target }: any) =>
                  setAuthorQuery(target.value)
                }
                renderResult={(el: any) => <span>{el.name}</span>}
                onSearch={handleAuthorSearch}
                onResultSelect={(author: any) => {
                  setFieldValue(name, author.id)
                  setAuthorName(author.name)
                  setAuthorQuery('')
                }}
                inputProps={{
                  name,
                  size: 30,
                  placeholder: 'Search for author by name',
                }}
              />
              <Link to='/authors/new' target='_blank' rel='noopener noreferrer'>
                Or create a new one
              </Link>
            </>
          )}
        </CustomFormikInput>

        <FormikTextField
          style={{ width: 300 }}
          name='display_time'
          label='Display Time'
          placeholder='e.g. 5 minute mantra, etc.'
        />

        <FormikMultiSelect
          name='categories'
          label='Categories'
          options={categoryOptions}
        />
        <FormikMultiSelect
          name='mood_categories'
          label='Mood Categories'
          options={moodOptions}
        />
        <FormikMultiSelect
          name='suit_categories'
          label='Suit Categories'
          options={suitOptions}
        />

        <CustomFormikInput name='growth_points' label='Growth Points'>
          {({ value, name }, { setFieldValue }) => (
            <StyledMultiSelect
              selected={value}
              options={allGrowthCategories}
              onSelectionChange={(selected: any) => {
                setFieldValue(name, selected)
              }}
              renderItem={({ handleSelectedDelete }: any) => (item: any) => {
                return (
                  <SelectedItem
                    className='selected-item'
                    key={item.key}
                    label={item.label}
                    onDeleteClick={() => handleSelectedDelete(item)}
                  >
                    <input
                      style={{ width: '3em', marginRight: 4 }}
                      type='number'
                      min='0'
                      max='99'
                      value={item.data}
                      onChange={({ target }) => {
                        const newGrowth = cloneDeep(value)
                        newGrowth.find(
                          ({ key }: any) => key === item.key
                        ).data = Math.max(
                          Math.min(parseInt(target.value), 99),
                          0
                        )
                        setFieldValue(name, newGrowth)
                      }}
                    />
                    <span>{item.label}</span>
                  </SelectedItem>
                )
              }}
            />
          )}
        </CustomFormikInput>

        <CustomFormikInput name='tags' label='Tags'>
          {({ value, name }, { setFieldValue }) => (
            <ChipMultiSelect
              list={values.tags}
              onListChange={(newList: any[]) => {
                setFieldValue(name, newList)
              }}
              onSearch={(val: string) => {
                return (tagOptions ?? []).filter(o =>
                  o.label.toLowerCase().includes(val.toLowerCase())
                )
              }}
              searchProps={{
                inputProps: {
                  size: 30,
                  placeholder: 'Search for a tag by name',
                },
              }}
            />
          )}
        </CustomFormikInput>

        <CustomFormikInput name='cover_photo_url' label='Cover Photo'>
          {({ value, ...inputProps }) => (
            <FileInput
              onChange={({ currentTarget }) => {
                // @ts-expect-error
                setFieldValue(inputProps.name, currentTarget.files[0])
              }}
              file={value}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <CustomFormikInput name='audio_src_url' label='Audio'>
          {({ value, ...inputProps }) => (
            <FileInput
              previewType='audio'
              onChange={({ currentTarget }) => {
                // @ts-expect-error
                setFieldValue(inputProps.name, currentTarget.files[0])
              }}
              file={value}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <Button
          variant='contained'
          color='primary'
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </StyledForm>
    </FormikProvider>
  )
}

export default MeditationForm
