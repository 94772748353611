// pale lavender - #DCD6F7

export default {
  primaryDark: '#603B3E',
  primary: '#995D62',
  primaryLight: '#D19499',
  secondaryDark: '#7EA4B2',
  secondary: '#B5EBFF',
  secondaryLight: '#DBF5FF',

  textDefault: '#070707',
  textInverse: '#F7F7F7',
}
