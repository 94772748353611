import { firestore, storage } from 'firebase'

export interface EntityDocument {
  _meta: {
    created_date: firestore.Timestamp
    modified_date: firestore.Timestamp
    enabled: boolean
  }
}

export function makeFirestoreEntityDocument<T>(
  data: T,
  enabled = true
): T & EntityDocument {
  return {
    ...data,
    _meta: {
      created_date: firestore.Timestamp.now(),
      modified_date: firestore.Timestamp.now(),
      enabled,
    },
  }
}

export async function uploadFile(
  type: string,
  ext: string,
  id: string,
  file: File
) {
  // if(!Object.keys(TYPES).some(t => t === type)) throw "Please provide a valid file upload type"
  // if(!TYPES[type].mimes.some(ext => ext === file.type)) throw `File is not a valid format! (Must be one of: ${TYPES[type].mimes})`
  const storageRef = storage().ref()
  const ref = storageRef.child(`${type}/${id}.${ext}`)
  await ref.put(file)
  const url = await ref.getDownloadURL()
  return url
}
