import { Button } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import FormikCheckbox from 'src/components/form-components/FormikCheckbox'
import FormikTextField from 'src/components/form-components/FormikTextField'
import { StyledForm } from 'src/components/form-components/styled-basics'
import { MeditationTag } from 'src/entities/MeditationTag'
import { CRUDFormProps } from '../types'
import validationSchema from './validation'

export type MeditationTagFormData = MeditationTag

const MeditationTagForm = ({
  item,
  onSubmit,
}: CRUDFormProps<MeditationTag, MeditationTagFormData>) => {
  const formik = useFormik({
    initialValues: {
      name: item?.name ?? '',
      description: item?.description ?? '',
      _enabled: item?._enabled ?? true,
    },
    validationSchema,
    onSubmit,
  })
  const { handleSubmit, values, setFieldValue, errors } = formik

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <FormikCheckbox name='_enabled' label='Enabled' />

        <FormikTextField name='name' label='Name' />

        <FormikTextField
          style={{ width: 300 }}
          name='description'
          label='Description'
        />

        <Button
          variant='contained'
          color='primary'
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </StyledForm>
    </FormikProvider>
  )
}

export default MeditationTagForm
