import { Container, List, ListProps } from '@material-ui/core'
import * as React from 'react'

export interface DataListProps<T extends any>
  extends Omit<ListProps, 'onClick'> {
  data: T[] | undefined
  renderItem: (
    // Gets the parameters of the first parameter of map.. which is the callback function's parameters
    ...args: Parameters<Parameters<T[]['map']>[0]>
  ) => JSX.Element | null
  EmptyListComponent?: () => JSX.Element
}

function DataList<T = any>({
  data,
  renderItem,
  EmptyListComponent,
  ...props
}: DataListProps<T>) {
  return (
    <List {...props}>
      {!data || data.length < 1
        ? EmptyListComponent && (
            <Container>
              <EmptyListComponent />
            </Container>
          )
        : data.map(renderItem)}
    </List>
  )
}

export default DataList
