import { omit } from 'lodash'
import React, { useRef } from 'react'
import SuitCategoryForm, {
  SuitCategoryFormData,
} from 'src/components/forms/suit'
import { SuitCategory, SuitCategoryAdapter } from 'src/entities/SuitCategory'
import CRUDMaker from 'src/pages/crud-maker'
import { uploadFile } from 'src/util/firestore-util'

const SuitCategoriesCRUD = () => {
  const adapter = useRef(new SuitCategoryAdapter()).current
  return (
    <CRUDMaker<SuitCategoryFormData, SuitCategory>
      entityName='Suit Categories'
      Form={SuitCategoryForm}
      adapter={adapter}
      onSubmit={async (item, id) => {
        const data = omit(item, 'image_src')
        if (!id) {
          id = await adapter.set(data)
        } else {
          await adapter.update(id, {
            name: item.name,
            description: item.description,
            _enabled: item._enabled,
          })
        }
        if (item.image_src instanceof File) {
          const url = await uploadFile(
            adapter.collectionName + '/image',
            'png',
            id,
            item.image_src
          )
          adapter.update(id, {
            image_src: url,
          })
        }
      }}
      dashboardColumns={[
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
      ]}
    />
  )
}

export default SuitCategoriesCRUD
