import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import { FormikConfig } from 'formik'
import moment from 'moment'
import * as React from 'react'
import BannerConfigForm from 'src/components/forms/BannerConfig'
import { BannerConfigurationInput } from 'src/components/forms/BannerConfig/schema'
import SimpleDataList from 'src/components/material-x/SimpleDataList'
import { BannerConfiguration, BannerConfigurationDocument } from 'src/types'
import BannerList, { BannerListProps } from './BannerList'

export interface BannersDashboardViewProps extends BannerListProps {
  // {} is for a new item
  selectedItem?: BannerConfiguration | {}
  onCreateClick: () => void
  onSaveSelectedItem: FormikConfig<BannerConfigurationInput>['onSubmit']
}

const BannersDashboardView: React.FC<BannersDashboardViewProps> = ({
  data,
  selectedItem,
  onCreateClick,
  onItemClick,
  onSaveSelectedItem,
  onToggleVisibilityClick,
  onDeleteClick,
  ...props
}) => {
  const styles = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} className={styles.listColumn}>
        <Button variant='contained' color='primary' onClick={onCreateClick}>
          Create New
        </Button>
        <Paper>
          <BannerList
            {...{ onItemClick, onToggleVisibilityClick, onDeleteClick, data }}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={styles.formContainer}>
          {selectedItem ? (
            <BannerConfigForm
              initialData={selectedItem}
              onSubmit={onSaveSelectedItem}
            />
          ) : (
            <Typography color='textSecondary'>
              Select a banner to edit it
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listColumn: {
      '& > *:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    formContainer: {
      minHeight: 200,
      padding: theme.spacing(1),
    },
  })
)

export default BannersDashboardView
