import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core'
import { ColDef, DataGrid, RowData } from '@material-ui/data-grid'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router'
import { toast } from 'react-toastify'
import { CRUDFormProps } from 'src/components/forms/types'
import { FirestoreCollectionAdapter, FirestoreEntity } from 'src/entities/base'
// @ts-expect-error
import styled from 'styled-components'

interface CRUDProps<V extends {}, E extends FirestoreEntity> {
  entityName: string
  adapter: FirestoreCollectionAdapter<E>
  dashboardColumns: ColDef[]
  Form: (props: CRUDFormProps<E, V>) => JSX.Element
}

type CRUDContextState<V extends {}, E extends FirestoreEntity> = CRUDProps<
  V,
  E
> & {
  handleSubmit: SubmitHandler<V>
}

type CRUDMakerProps<V extends {}, E extends FirestoreEntity> = CRUDProps<
  V,
  E
> & {
  onSubmit: SubmitHandler<V>
}

const CRUDContext = createContext<
  CRUDContextState<any, FirestoreEntity> | undefined
>(undefined)

type SubmitHandler<T> = (data: T, id?: string) => Promise<void> | void

function CRUDMaker<V extends {}, E extends FirestoreEntity>({
  onSubmit,
  ...props
}: CRUDMakerProps<V, E>) {
  const { push } = useHistory()
  const { path } = useRouteMatch()

  const handleSubmit: SubmitHandler<V> = async (data, id) => {
    try {
      await onSubmit(data, id)
      toast.success('Entity updated successfully!')
      push(path)
    } catch (e) {
      toast.error('There was an error submitting this form:\n' + e.message)
    }
  }

  return (
    <CRUDContext.Provider
      //@ts-ignore
      value={{
        handleSubmit,
        ...props,
      }}
    >
      <Content />
    </CRUDContext.Provider>
  )
}

function Content() {
  const { path } = useRouteMatch()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const toPath = (end: string) => `${path}${end}`

  const { entityName } = useContext(CRUDContext)!

  const subPath = pathname.replace(path, '').match(/(?!\/)[^\/]*/)?.[0]

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Breadcrumbs style={{ marginBottom: 20 }} aria-label='breadcrumb'>
        <Link color='inherit' onClick={() => push(path)}>
          {entityName}
        </Link>
        {subPath && (
          <Typography
            color='textPrimary'
            style={{ textTransform: 'capitalize' }}
          >
            {subPath}
          </Typography>
        )}
      </Breadcrumbs>
      <Switch>
        <Route exact path={path}>
          <Dashboard
            onNew={() => push(toPath('/new'))}
            onEdit={id => push(toPath(`/edit/${id}`))}
          />
        </Route>
        <Route path={toPath('/new')}>
          <New />
        </Route>
        <Route path={toPath('/edit/:id')}>
          <Edit />
        </Route>
      </Switch>
    </div>
  )
}

interface DashboardProps {
  onNew: () => void
  onEdit: (id: string) => void
}

const DashboardActions = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-end;

  margin-bottom: 0.5rem;
`

function Dashboard({ onNew, onEdit }: DashboardProps) {
  const [rows, setRows] = useState<RowData[]>()
  const [selectedRows, setSelectedRows] = useState<RowData[]>([])

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)

  const { adapter, dashboardColumns } = useContext(CRUDContext)!

  useEffect(() => {
    adapter.collection.get().then(({ docs }) => {
      setRows(docs.map(doc => ({ id: doc.id, ...doc.data() })))
    })
  }, [])

  const handleDeleteClick = () => {
    setShowDeleteConfirmDialog(true)
  }

  const handleDeleteConfirm = () => {
    setShowDeleteConfirmDialog(false)
    const idsToDelete = selectedRows.map(r => r.id)
    adapter.collection.firestore
      .runTransaction(async t => {
        idsToDelete.forEach(id =>
          t.delete(adapter.collection.doc(id.toString()))
        )
      })
      .then(() => {
        setRows(rows!.filter(r => !idsToDelete.includes(r.id)))
      })
  }

  const displayedDashboardColumns = useMemo(
    () => [
      { field: '_enabled', headerName: 'Enabled', width: 100 },
      ...dashboardColumns,
    ],
    [dashboardColumns]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <DashboardActions>
        <Button variant='contained' onClick={onNew}>
          Add new
        </Button>
        <div style={{ width: 10 }} />
        <Button
          variant='contained'
          onClick={handleDeleteClick}
          disabled={selectedRows.length < 1}
        >
          Delete Rows
        </Button>
      </DashboardActions>
      <div style={{ flexGrow: 1, alignSelf: 'stretch' }}>
        <div style={{ height: '100%', minWidth: 400, width: '100%' }}>
          <DataGrid
            rows={rows ?? []}
            columns={displayedDashboardColumns}
            loading={rows === undefined}
            onRowClick={({ data }: any) => {
              onEdit(data.id.toString())
            }}
            checkboxSelection
            //@ts-ignore
            onSelectionChange={({ rows }: any) => {
              setSelectedRows(rows)
            }}
          />
        </div>
      </div>
      <ConfirmDeleteAlertDialog
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  )
}

function New() {
  const { Form, handleSubmit } = useContext(CRUDContext)!
  return <Form isEdit={false} onSubmit={item => handleSubmit(item)} />
}

function Edit() {
  const { Form, handleSubmit, adapter } = useContext(CRUDContext)!

  const slug = useParams<{ id: string }>()

  const [item, setItem] = useState<FirestoreEntity | null>()

  useEffect(() => {
    if (!slug?.id) return
    adapter.get(slug.id).then(x => setItem(x.data()))
  }, [slug?.id])

  if (!slug.id) return <div>No id given</div>

  if (item === undefined) return <div>Loading</div>

  if (item === null) return <div>Item not found</div>

  return (
    <Form
      isEdit={true}
      onSubmit={item => handleSubmit(item, slug.id)}
      item={item}
    />
  )
}

function ConfirmDeleteAlertDialog({
  open = false,
  onClose = () => {},
  onConfirm = () => {},
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure that you want to delete these entities? This action
            cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={onConfirm} color='primary' variant='contained'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CRUDMaker
