import { useState, useEffect, useCallback } from 'react'
import useEffectSkipInitial from 'src/hooks/useEffectSkipInitial'

/**
 * Bundles all basic functionality for forms into one hook
 * @param  {Object} initialValues        Initial values for the form's inputs
 * @param  {Function} submit             Function for submitting the form
 * @param  {Function} validate           Function which validates the form
 * @param  {Object} config               Additional configuration options
 * @return {Object}                      Object containing form state
 */
export function useForm(
  initialValues,
  submit,
  validate,
  { enterToSubmit = false } = {}
) {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  // Tracks which inputs have been interacted with yet
  const [visited, setVisited] = useState({})

  // "Private"; only for tracking changes in files to validate on change
  const [_files, _setFiles] = useState({})

  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (isSubmitting) {
      // Error length of zero implies no errors, aka ok to submit
      if (Object.keys(errors).length === 0) {
        submit(values)
        setSubmitting(false)
      } else {
        setSubmitting(false)
      }
    }
  }, [errors, isSubmitting, submit, values])

  // Checks values when files change
  useEffectSkipInitial(() => {
    setErrors(validate(values))
  }, [_files])

  const handleChange = ({ target }) => {
    var value
    if (target.type === 'checkbox') {
      value = target.checked
    } else {
      value = target.value
    }

    setValues({
      ...values,
      [target.name]: value,
    })
  }

  const handleFileChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.files[0],
    })
    _setFiles({
      ..._files,
      [target.name]: target.files[0],
    })
    // File inputs by default do not use handleBlur(),
    //  so visited gets checked here
    if (!visited[target.name])
      setVisited({
        ...visited,
        [target.name]: true,
      })
  }

  const setValue = useCallback(
    (name, value) => {
      setValues({
        ...values,
        [name]: value,
      })
    },
    [values]
  )

  const handleBlur = ({ target }) => {
    if (!visited[target.name])
      setVisited({
        ...visited,
        [target.name]: true,
      })
    setErrors(validate(values))
  }

  const sendSubmit = useCallback(() => {
    setErrors(validate(values))
    // Sets all inputs to visited
    setVisited(
      Object.keys(initialValues).reduce((acc, cur) => {
        acc[cur] = true
        return acc
      }, {})
    )
    setSubmitting(true)
  }, [values, initialValues, validate])

  const handleSubmit = event => {
    event.preventDefault()
    sendSubmit()
  }

  // If configured, then the form submit's when one of it's children is focused on enter keyup
  useEffect(() => {
    if (!enterToSubmit) return
    function submitOnEnter({ code }) {
      if (
        code === 'Enter' &&
        document.activeElement.getAttribute('form') === 'useForm'
      ) {
        sendSubmit()
      }
    }
    document.addEventListener('keyup', submitOnEnter)
    return () => document.removeEventListener('keyup', submitOnEnter)
  }, [enterToSubmit, sendSubmit])

  return {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleFileChange,
    handleBlur,
    handleSubmit,
    visited,
    setValue,
  }
}
