import React from 'react'

import styled from 'styled-components'

import { useRouteMatch } from 'react-router-dom'

import LinkButton from 'src/components/LinkButton'

const SidebarLink = styled(LinkButton)`
  margin-bottom: 0.25rem;
  color: #0000;
`

const Heading = styled.div`
  padding-top: 10px;
`

export default function Sidebar({ links, ...props }) {
  // Docs say to use url, but on urls like /admin/ it bugs out
  let { url } = useRouteMatch()

  return (
    <div {...props}>
      {links.map(l => (
        //this needs to be wrapped in a fragment
        <>
          <Heading>{l.head}</Heading>
          {l.children.map(c => (
            <SidebarLink key={c.to} to={`${url}${l.path}${c.to}`}>
              {c.title}
            </SidebarLink>
          ))}
        </>
      ))}
    </div>
  )
}
