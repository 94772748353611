import { Button, Checkbox } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import React from 'react'
import CustomFormikInput from 'src/components/form-components/CustomFormikInput'
import FormikCheckbox from 'src/components/form-components/FormikCheckbox'
import FormikTextField from 'src/components/form-components/FormikTextField'
import FileInput from 'src/components/form-components/FileInput'
import SimpleSelect from 'src/components/form-components/SimpleSelect'
import { StyledForm } from 'src/components/form-components/styled-basics'
import { SimpleThemeColor } from 'src/entities/enums'
import { MoodCategory } from 'src/entities/MoodCategory'
import { CRUDFormProps } from '../types'
import validationSchema from './validation'

const colorsAsDataList = Object.entries(SimpleThemeColor).map(
  ([label, key]) => ({
    label,
    key,
  })
)

export type MoodCategoryFormData = Omit<MoodCategory, 'image_src'> & {
  image_src: File | string
}

const MoodCategoryForm = ({
  item,
  onSubmit,
}: CRUDFormProps<MoodCategory, MoodCategoryFormData>) => {
  const formik = useFormik({
    initialValues: {
      name: item?.name ?? '',
      description: item?.description ?? '',
      background_color: item?.background_color ?? '',
      image_src: item?.image_src ?? '',
      _enabled: item?._enabled ?? true,
    },
    validationSchema,
    onSubmit,
  })
  const { handleSubmit, values, setFieldValue, errors } = formik

  return (
    <FormikProvider value={formik}>
      <StyledForm>
        <FormikCheckbox name='_enabled' label='Enabled' />

        <FormikTextField name='name' label='Name' />

        <FormikTextField
          style={{ width: 300 }}
          name='description'
          label='Description'
        />

        <CustomFormikInput
          name='background_color'
          label='Background Color'
          helperText='Background color determines the color of components which display the category image. Consult the designs for clarification.'
        >
          {inputProps => (
            <SimpleSelect
              style={{ minWidth: 200 }}
              data={colorsAsDataList}
              onChange={({ target }) => {
                setFieldValue(inputProps.name, target.value)
              }}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <CustomFormikInput name='image_src' label='Image'>
          {({ value, ...inputProps }) => (
            <FileInput
              type='file'
              onChange={({ currentTarget }) => {
                // @ts-expect-error
                setFieldValue(inputProps.name, currentTarget.files[0])
              }}
              // @ts-expect-error
              file={values.image_src}
              {...inputProps}
            />
          )}
        </CustomFormikInput>

        <Button
          variant='contained'
          color='primary'
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </StyledForm>
    </FormikProvider>
  )
}

export default MoodCategoryForm
