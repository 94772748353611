import * as yup from 'yup'

export const fileTypeSets = {
  firebaseImage: ['image/png', 'image/jpeg'],
}

/** Custom Yup functions and shorteners
 */
export const cyup = {
  ensureFile: ({
    validFileTypes,
    required = false,
  }: {
    validFileTypes: string[]
    required?: boolean
  }) =>
    yup.lazy(value => {
      return typeof value === 'string'
        ? yup.string()
        : !required && !value
        ? yup.mixed()
        : yup
            .mixed()
            .test('required', 'This is required', v => !!v)
            .test(
              'isFile',
              'If not a string, must be a file',
              v => v instanceof File
            )
            .test('isCorrectFormat', 'Incorrect file type', v => {
              return validFileTypes.includes((v as File)?.type)
            })
    }),

  oneOfStringValue: (object: Record<any, string>) =>
    yup.string().required().oneOf(Object.values(object)),
}
