import * as yup from 'yup'
import { SuitCategoryFormData } from '.'
import { cyup, fileTypeSets } from 'src/util/validation'

//@ts-ignore TODO FIXME
const validationSchema = yup.object().shape<SuitCategoryFormData>({
  name: yup.string().required().min(1),
  description: yup.string(),
  image_src: cyup.ensureFile({ validFileTypes: fileTypeSets.firebaseImage }),
})

export default validationSchema
