import { firestore } from 'firebase'

const CACHE_KEY = '@MEDITATION_CACHE'
const UPDATE_KEY = '@MEDITATION_CACHE_LAST_UPDATE'

export default class MeditationSearchCache {
  // eslint-disable-next-line no-undef
  static _instance

  /**
   * @type {MeditationSearchCache}
   */
  static get instance() {
    if (!this._instance) {
      const cachedInstance = window.sessionStorage.getItem(CACHE_KEY)
      this._instance = new this(JSON.parse(cachedInstance) ?? undefined)
    }
    return this._instance
  }

  constructor(cache = []) {
    this.meditations = cache
  }

  async findMeditationWithNameLike(like = '', limit = 10) {
    if (this.meditations.length < 1) {
      await this.updateCache()
    }
    return this.meditations
      .filter(m => m.name.toLowerCase().match(like.toLowerCase()))
      .slice(0, limit)
  }

  async updateCache() {
    if (!this._isCacheValid()) {
      const allMeditations = await firestore().collection('meditations').get()
      this.meditations = allMeditations.docs.map(doc => {
        const { name, cover_photo_url } = doc.data()
        return {
          name,
          cover_photo_url,
          id: doc.id,
        }
      })
      this.saveToStorage()
    }
  }

  async saveToStorage() {
    window.sessionStorage.setItem(CACHE_KEY, JSON.stringify(this.meditations))
  }

  _isCacheValid() {
    const lastUpdate = window.sessionStorage.getItem(UPDATE_KEY)
    return lastUpdate && lastUpdate > Date.now() - 1000 * 60 * 10
  }
}
