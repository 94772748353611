export enum SimpleThemeColor {
  DeepRose = 'deep_rose',
  DeepRoseLight = 'deep_rose_light',
  Bittersweet = 'bittersweet',
  BittersweetLight = 'bittersweet_light',
  Orchid = 'orchid',
  OrchidLight = 'orchid_light',
  YellowOrange = 'yellow_orange',
  YellowOrangeLight = 'yellow_orange_light',
}

export enum GrowthCategory {
  SocialEase = 'social_ease',
  Openness = 'openness',
  Diplomacy = 'diplomacy',
  Leadership = 'leadership',
  Vision = 'vision',
  Responsibility = 'responsibility',
  Organization = 'organization',
  SelfConfidence = 'self_confidence',
  Independence = 'independence',
  Creativity = 'creativity',
  StressManagement = 'stress_management',
  Responsiveness = 'responsiveness',
  Patience = 'patience',
  Determination = 'determination',
  Ambition = 'ambition',
  WorkEthic = 'work_ethic',
}
