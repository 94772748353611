import { yupDataPair } from 'src/components/material-x/formik/PropertyListField'
import * as yup from 'yup'

const schema = yup.object({
  action: yup.string().optional().oneOf(['show-screen', 'redirect']),
  // Screen Navigation
  screenName: yup.string().when('action', {
    is: 'show-screen',
    then: yup.string().required(),
  }),
  screenParameters: yupDataPair(),
  // URL Redirect
  url: yup.string().when('action', {
    is: 'redirect',
    then: yup.string().required().url(),
  }),
})

export type BannerConfigurationButtonActionInput = yup.TypeOf<typeof schema>

export default schema
