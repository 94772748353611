import { FirestoreEntity, FirestoreCollectionAdapter } from './base'

export interface MeditationTag extends FirestoreEntity {
  name: string
  description?: string
}

export class MeditationTagAdapter extends FirestoreCollectionAdapter<MeditationTag> {
  constructor() {
    super('meditation_tags')
  }
}
