// import { ListItemText } from '@material-ui/core'
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import moment from 'moment'
import * as React from 'react'
import DataList, { DataListProps } from 'src/components/material-x/DataList'
import { BannerConfigurationDocument } from 'src/types'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export interface BannerListProps {
  data: BannerConfigurationDocument[] | undefined
  onItemClick: (item: BannerConfigurationDocument, index: number) => void
  onToggleVisibilityClick: (isVisible: boolean, index: number) => void
  onDeleteClick: (index: number) => void
}

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: 96,
  },
})(ListItem)

const BannerList: React.FC<BannerListProps> = ({
  data,
  onItemClick,
  onToggleVisibilityClick,
  onDeleteClick,
}) => {
  // const classes = useStyles()

  const renderItem: DataListProps<BannerConfigurationDocument>['renderItem'] = React.useCallback(
    (item, i, { length }) => {
      const { content } = item.configuration
      return (
        <ListItemWithWiderSecondaryAction
          button
          divider={i !== length - 1}
          onClick={() => onItemClick(item, i)}
        >
          <ListItemText
            primary={content.title || content.text || 'Unnamed'}
            secondary={`${
              item.enabled ? 'Enabled' : 'Disabled'
            } | Last updated on ${moment(item.last_updated_at.toDate()).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            )} by ${item.last_updated_by}`}
          />
          <ListItemSecondaryAction>
            <IconButton
              onClick={() => onToggleVisibilityClick(!item.enabled, i)}
            >
              {item.enabled ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton onClick={() => onDeleteClick(i)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemWithWiderSecondaryAction>
      )
    },
    [onItemClick]
  )

  return (
    <DataList<BannerConfigurationDocument>
      data={data}
      renderItem={renderItem}
    />
  )
}

export default BannerList
