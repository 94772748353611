import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Authors from 'src/pages/Authors'
import Meditations from 'src/pages/Meditations'
import Sandbox from 'src/pages/Sandbox'
import { aaxios } from 'src/util/app-axios'
// @ts-expect-errors
import styled from 'styled-components'
import Banners from '../banners'
import MeditationCategoriesCRUD from '../crud/MeditationCategories'
import MeditationTagsCRUD from '../crud/MeditationTags'
import MoodCategoriesCRUD from '../crud/MoodCategories'
import SuitCategoriesCRUD from '../crud/SuitCategories'
import Roles from './Roles'
import Sidebar from './Sidebar'

const StyledSidebar = styled(Sidebar)`
  padding: 1rem;
  border-right: 1px solid lightgrey;
  max-width: 20%;
  min-width: 200px;
`

const MainContentContainer = styled.div`
  padding: 1rem;
  align-items: flex-start;
  width: 100%;
`

const AnalyticsContent = styled.div`
  padding: 1rem;
  align-content: flex-start;
`

const links = [
  {
    head: 'Meditations',
    path: 'meditations',
    children: [
      { title: 'Create/Manage', to: '' },
      { title: 'Set Daily Meditation', to: '/daily-meditation' },
      { title: 'Search Meditations', to: '/search' },
    ],
  },
  {
    head: 'Authors',
    path: 'authors',
    children: [{ title: 'Add New Author', to: '/new' }],
  },
  {
    head: 'Others',
    path: '',
    children: [
      { title: 'Meditation Categories', to: 'meditation-categories' },
      { title: 'Meditation Tags', to: 'meditation-tags' },
      { title: 'Mood Categories', to: 'mood-categories' },
      { title: 'Suit Categories', to: 'suit-categories' },
      { title: 'Banners', to: 'banners' },
    ],
  },
  {
    head: 'Roles',
    path: 'roles',
    children: [{ title: 'Manage Roles', to: '/manage' }],
  },
]

export default function Admin() {
  let { path } = useRouteMatch()
  const toPath = (end: string) => `${end}`
  const [result, setResult] = React.useState('loading')
  //setting the result for the analytics
  React.useEffect(() => {
    ;(async () => {
      // use axios to setResult
      setResult((await (await aaxios()).get('/analytics/main')).data)
    })()
  }, [])

  return (
    <>
      <StyledSidebar links={links} />
      <MainContentContainer>
        <Switch>
          <Route exact path={path}>
            <h3>Select a tab to get started!</h3>
            {/* <h4>Analytics about our content:</h4> */}
            {/* <AnalyticsContent> Most Popular Meditation: {typeof result === "string" ? result: result.mostPopularMeditation.name + " with " + result.mostPopularMeditation.listens +" listens"} </AnalyticsContent>
            <AnalyticsContent> Most Popular Author: {typeof result === "string" ? result : result.mostPopularAuthor.name +" with "+ result.mostPopularAuthor.listens +" listens"} </AnalyticsContent>
            <AuthorAnalytics authorList={typeof result === "string" ? result : result.authorList}/> */}
          </Route>
          <Route path={toPath('/meditations')}>
            <Meditations />
          </Route>
          <Route path={toPath('/authors')}>
            <Authors />
          </Route>
          <Route path={toPath('/meditation-categories')}>
            <MeditationCategoriesCRUD />
          </Route>
          <Route path={toPath('/meditation-tags')}>
            <MeditationTagsCRUD />
          </Route>
          <Route path={toPath('/mood-categories')}>
            <MoodCategoriesCRUD />
          </Route>
          <Route path={toPath('/suit-categories')}>
            <SuitCategoriesCRUD />
          </Route>
          <Route path={toPath('/banners')}>
            <Banners />
          </Route>
          <Route path={toPath('/roles')}>
            <Roles />
          </Route>
          {process.env.NODE_ENV !== 'production' && (
            <Route path={toPath('/sandbox')}>
              <Sandbox />
            </Route>
          )}
        </Switch>
      </MainContentContainer>
    </>
  )
}
