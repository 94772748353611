import React from 'react'

import styled from 'styled-components'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'

import Header from './Header'
import Admin from 'src/pages/Admin'
import Login from 'src/pages/Login'

// -- Notifications
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// --

import RequireAuthRoute from 'src/components/router/RequireAuthRoute'
import useAuth, { ProvideAuth } from 'src/hooks/useAuth'

// -- Firebase set up
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyATH7WC42rI2mvVd9N4BW_iiKXevHOF5UI',
  authDomain: 'prana-heart.firebaseapp.com',
  databaseURL: 'https://prana-heart.firebaseio.com',
  projectId: 'prana-heart',
  storageBucket: 'prana-heart.appspot.com',
  messagingSenderId: '403277030006',
  appId: '1:403277030006:web:111d0b0c5cbbb4297ef557',
  measurementId: 'G-68VDH0XCPC',
}
const firebaseApp = firebase.initializeApp(firebaseConfig)
// --

const AppContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid lightgrey;
  flex: 1;
`

function AppRouter() {
  const { user } = useAuth()

  return (
    <Router>
      <AppContainer>
        <Header />
        <ContentContainer>
          <Switch>
            <Route exact path='/login'>
              {!user ? <Login /> : <Redirect to='/' />}
            </Route>
            <RequireAuthRoute path='/'>
              <Admin />
            </RequireAuthRoute>
          </Switch>
        </ContentContainer>
      </AppContainer>
    </Router>
  )
}

function App() {
  return (
    <ProvideAuth>
      <AppRouter />
      <ToastContainer hideProgressBar={true} />
    </ProvideAuth>
  )
}

export default App
