import { omit } from 'lodash'
import React, { useRef } from 'react'
import MoodCategoryForm, {
  MoodCategoryFormData,
} from 'src/components/forms/mood'
import { SimpleThemeColor } from 'src/entities/enums'
import { MoodCategory, MoodCategoryAdapter } from 'src/entities/MoodCategory'
import CRUDMaker from 'src/pages/crud-maker'
import { uploadFile } from 'src/util/firestore-util'

const MoodCategoriesCRUD = () => {
  const adapter = useRef(new MoodCategoryAdapter()).current
  return (
    <CRUDMaker<MoodCategoryFormData, MoodCategory>
      entityName='Mood Categories'
      Form={MoodCategoryForm}
      adapter={adapter}
      onSubmit={async (item, id) => {
        const data = omit(item, 'image_src')
        if (!id) {
          id = await adapter.set(data)
        } else {
          await adapter.update(id, {
            background_color: item.background_color as SimpleThemeColor,
            name: item.name,
            description: item.description,
            _enabled: item._enabled,
          })
        }
        if (item.image_src instanceof File) {
          const url = await uploadFile(
            adapter.collectionName + '/image',
            'png',
            id,
            item.image_src
          )
          adapter.update(id, {
            image_src: url,
          })
        }
      }}
      dashboardColumns={[
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
      ]}
    />
  )
}

export default MoodCategoriesCRUD
