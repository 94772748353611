import { useFormikContext } from 'formik'
import * as React from 'react'
import SelectField from 'src/components/material-x/formik/Select'
import TextInput from 'src/components/material-x/formik/TextInput'
import { AnyButtonAction } from 'src/types'
import PropertyListField from '../../../material-x/formik/PropertyListField'
export interface ButtonActionFieldsProps {
  baseProperty: string
}

const ButtonActionFields: React.FC<ButtonActionFieldsProps> = ({
  baseProperty,
}) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const actionValues: AnyButtonAction = values[baseProperty]

  const actionSpecificComponents = React.useMemo(() => {
    switch (actionValues?.action) {
      case 'show-screen':
        if (!Array.isArray(actionValues.screenParameters)) {
          setFieldValue(baseProperty + '.screenParameters', [])
          setFieldValue(baseProperty + '.screenName', '')
        }
        return (
          <>
            <TextInput
              label='Screen Name'
              name={baseProperty + '.screenName'}
            />
            <PropertyListField
              label='Screen Parameters'
              name={baseProperty + '.screenParameters'}
            />
          </>
        )
      case 'redirect':
        return (
          <>
            <TextInput label='URL' name={baseProperty + '.url'} />
          </>
        )
      default:
        return null
    }
  }, [baseProperty, actionValues, setFieldValue])

  // TODO: Somehow determine how to apply the object shape when a non-null action is selected

  return (
    <>
      <SelectField
        label='Action'
        name={baseProperty + '.action'}
        options={[null, 'show-screen', 'redirect']}
      />
      {actionSpecificComponents}
    </>
  )
}

export default ButtonActionFields
